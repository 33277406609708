<ng-container *ngFor="let b of blockData; let last = last">
  <ng-container *ngIf="b.position == this.position">
    <ng-container *ngIf="b.isCreateBase">
      <!-- 見出し -->
      <ng-container *ngIf="b.blockType == 'title' && b.title != ''">
        <div class="block">
          <div class="blockContent title">
            <h2 class="block__title secHeading" [class.__modalStyle__]="isShopDetailNav">{{ b.title }}</h2>
          </div>
        </div>
      </ng-container>
      <!-- テキスト -->
      <ng-container *ngIf="b.blockType == 'text' && b.text != ''">
        <div class="block">
          <div class="blockContent text">
            <p class="block__text" [class.__modalStyle__]="isShopDetailNav" [innerHTML]="b.textHTML"></p>
          </div>
        </div>
      </ng-container>
      <!-- 画像 -->
      <ng-container *ngIf="b.blockType == 'image' && b.image != ''">
        <div class="block">
          <div class="blockContent image">
            <div class="block__eyecatch secContent" [class.__modalStyle__]="isShopDetailNav">
              <div class="block__eyecatch__inner">
                <picture>
                  <source *ngIf="b.webpImage" type="image/webp" srcset="{{ b.webpImage }}" />
                  <img
                    class="block__eyecatch__inner__img"
                    src="{{ b.image }}"
                    alt="{{ b.imageAlt }}"
                    loading="lazy"
                    [class.width90]="b.imageDisplayType == 2"
                    [class.width50]="b.imageDisplayType == 3"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- 動画 -->
      <ng-container *ngIf="b.blockType == 'video' && b.imageIframe != ''">
        <div class="block">
          <div class="blockContent image">
            <div class="block__eyecatch secContent" [class.__modalStyle__]="isShopDetailNav">
              <div class="block__eyecatch__inner">
                <div class="block__eyecatch__video" [innerHTML]="b.imageHTML" #videoBlock></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- 区切り線 -->
      <ng-container *ngIf="b.blockType == 'line'">
        <div class="block">
          <div class="blockContent line"><hr class="block__line" [class.__modalStyle__]="isShopDetailNav" /></div>
        </div>
      </ng-container>
      <!-- ボタン -->
      <ng-container *ngIf="b.blockType == 'button'">
        <div class="block">
          <div class="blockContent button">
            <ng-container [ngSwitch]="b.buttonLinkType">
              <!-- 外部リンク -->
              <ng-container *ngSwitchCase="'url'">
                <a
                  href="javascript:void(0);"
                  class="block__button"
                  [class.__modalStyle__]="isShopDetailNav"
                  (click)="clickUrlButtonBlock(b)"
                  >{{ b.buttonText }}</a
                >
              </ng-container>
              <!-- 内部リンク -->
              <ng-container *ngSwitchCase="'internalUrl'">
                <a
                  href="javascript:void(0);"
                  class="block__button"
                  [class.__modalStyle__]="isShopDetailNav"
                  (click)="clickInternalUrlButtonBlock(b)"
                  >{{ b.buttonText }}</a
                >
              </ng-container>
              <!-- メール -->
              <ng-container *ngSwitchCase="'email'">
                <a
                  class="block__button"
                  [class.__modalStyle__]="isShopDetailNav"
                  href="mailto:{{ b.buttonEmailAddress }}?subject={{ b.buttonEmailTitle }}&body={{ b.buttonEmailBody }}"
                  >{{ b.buttonText }}</a
                >
              </ng-container>
              <!-- 電話番号 -->
              <ng-container *ngSwitchCase="'tel'">
                <a class="block__button" [class.__modalStyle__]="isShopDetailNav" href="tel:{{ b.buttonTel }}">{{
                  b.buttonText
                }}</a>
              </ng-container>
              <ng-container *ngSwitchDefault></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- 店舗リンク -->
      <ng-container *ngIf="b.blockType == 'shopLink'">
        <div class="block">
          <div class="blockContent shopLink">
            <a
              href="javascript:void(0);"
              class="block__shopLink"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickShopLinkBlock(b.buttonUrlBody)"
              >{{ b.buttonText }}</a
            >
          </div>
        </div>
      </ng-container>
      <!-- 表 -->
      <ng-container *ngIf="b.blockType == 'table' && b.isDisplayGroup">
        <div class="block">
          <div class="blockContent table">
            <table class="block__table" [class.__modalStyle__]="isShopDetailNav">
              <ng-container *ngFor="let tableBlock of blockData">
                <ng-container *ngIf="b.groupId == tableBlock.groupId">
                  <tr class="block__table__tr" *ngIf="tableBlock.title != '' || tableBlock.text != ''">
                    <td class="block__table__td __title">
                      <p [innerHTML]="tableBlock.titleHTML"></p>
                    </td>
                    <td class="block__table__td __text">
                      <p [innerHTML]="tableBlock.textHTML"></p>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </table>
          </div>
        </div>
      </ng-container>
      <!-- ２列レイアウト -->
      <ng-container
        *ngIf="
          b.blockType == 'column_title' ||
          b.blockType == 'column_text' ||
          b.blockType == 'column_image' ||
          b.blockType == 'column_video' ||
          b.blockType == 'column_line' ||
          b.blockType == 'column_button' ||
          b.blockType == 'column_shopLink'
        "
      >
        <div class="block">
          <div class="blockContent column">
            <div class="block__columnGroup column2">
              <ng-container *ngFor="let column of arrayColumnNumList(b.columnNum)">
                <div class="block__column">
                  <ng-container *ngFor="let columnBlock of blockData">
                    <ng-container *ngIf="b.groupId == columnBlock.groupId && columnBlock.columnNum == column">
                      <!-- 見出し -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_title' && columnBlock.title != ''">
                        <div class="column__block">
                          <div class="column__blockContent title">
                            <h2 class="block__title secHeading" [class.__modalStyle__]="isShopDetailNav">
                              {{ columnBlock.title }}
                            </h2>
                          </div>
                        </div>
                      </ng-container>
                      <!-- テキスト -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_text' && columnBlock.text != ''">
                        <div class="column__block">
                          <div class="column__blockContent text">
                            <p
                              class="block__text"
                              [class.__modalStyle__]="isShopDetailNav"
                              [innerHTML]="columnBlock.textHTML"
                            ></p>
                          </div>
                        </div>
                      </ng-container>
                      <!-- 画像 -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_image' && columnBlock.image != ''">
                        <div class="column__block">
                          <div class="column__blockContent image">
                            <div class="block__eyecatch secContent" [class.__modalStyle__]="isShopDetailNav">
                              <div class="block__eyecatch__inner">
                                <picture class="block__eyecatch__inner__picture">
                                  <source
                                    *ngIf="columnBlock.webpImage"
                                    type="image/webp"
                                    srcset="{{ columnBlock.webpImage }}"
                                  />
                                  <img
                                    class="block__eyecatch__inner__img objectFitContents"
                                    src="{{ columnBlock.image }}"
                                    alt="{{ columnBlock.imageAlt }}"
                                    loading="lazy"
                                    [class.width90]="columnBlock.imageDisplayType == 2"
                                    [class.width50]="columnBlock.imageDisplayType == 3"
                                  />
                                </picture>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <!-- 動画 -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_video' && columnBlock.imageIframe != ''">
                        <div class="column__block">
                          <div class="column__blockContent image">
                            <div class="block__eyecatch secContent" [class.__modalStyle__]="isShopDetailNav">
                              <div class="block__eyecatch__inner">
                                <div
                                  class="block__eyecatch__video"
                                  [innerHTML]="columnBlock.imageHTML"
                                  #videoBlock
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <!-- 区切り線 -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_line'">
                        <div class="column__block">
                          <div class="column__blockContent line">
                            <hr class="block__line" [class.__modalStyle__]="isShopDetailNav" />
                          </div>
                        </div>
                      </ng-container>
                      <!-- ボタン -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_button'">
                        <div class="column__block">
                          <div class="column__blockContent button">
                            <ng-container [ngSwitch]="columnBlock.buttonLinkType">
                              <!-- 外部リンク -->
                              <ng-container *ngSwitchCase="'url'">
                                <a
                                  href="javascript:void(0);"
                                  class="block__button"
                                  [class.__modalStyle__]="isShopDetailNav"
                                  (click)="clickUrlButtonBlock(columnBlock)"
                                  >{{ columnBlock.buttonText }}</a
                                >
                              </ng-container>
                              <!-- 内部リンク -->
                              <ng-container *ngSwitchCase="'internalUrl'">
                                <a
                                  href="javascript:void(0);"
                                  class="block__button"
                                  [class.__modalStyle__]="isShopDetailNav"
                                  (click)="clickInternalUrlButtonBlock(columnBlock)"
                                  >{{ columnBlock.buttonText }}</a
                                >
                              </ng-container>
                              <!-- メール -->
                              <ng-container *ngSwitchCase="'email'">
                                <a
                                  class="block__button"
                                  [class.__modalStyle__]="isShopDetailNav"
                                  href="mailto:{{ columnBlock.buttonEmailAddress }}?subject={{
                                    columnBlock.buttonEmailTitle
                                  }}&body={{ columnBlock.buttonEmailBody }}"
                                  >{{ columnBlock.buttonText }}</a
                                >
                              </ng-container>
                              <!-- 電話番号 -->
                              <ng-container *ngSwitchCase="'tel'">
                                <a
                                  class="block__button"
                                  [class.__modalStyle__]="isShopDetailNav"
                                  href="tel:{{ columnBlock.buttonTel }}"
                                  >{{ columnBlock.buttonText }}</a
                                >
                              </ng-container>
                              <ng-container *ngSwitchDefault></ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                      <!-- 店舗リンク -->
                      <ng-container *ngIf="columnBlock.blockType == 'column_shopLink'">
                        <div class="column__block">
                          <div class="column__blockContent shopLink">
                            <a
                              href="javascript:void(0);"
                              class="block__shopLink"
                              [class.__modalStyle__]="isShopDetailNav"
                              (click)="clickShopLinkBlock(columnBlock.buttonUrlBody)"
                              >{{ columnBlock.buttonText }}</a
                            >
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- 商品・メニュー紹介 -->
      <ng-container *ngIf="b.blockType == 'introduction' && b.isDisplayGroup">
        <div class="block">
          <div class="blockContent introduction">
            <div class="block__introductionGroup">
              <ng-container *ngFor="let introductionBlock of blockData">
                <ng-container *ngIf="b.groupId == introductionBlock.groupId">
                  <div
                    class="block__introduction"
                    [class.visualRight]="!introductionBlock.isIntroductionVisualLeft"
                    [class.__modalStyle__]="isShopDetailNav"
                    *ngIf="introductionBlock.isIntroductionTextInfoArea || introductionBlock.isIntroductionVisualArea"
                  >
                    <div
                      class="block__introduction__section __sectionTextInfo"
                      *ngIf="introductionBlock.isIntroductionTextInfoArea"
                      [class.w50]="
                        introductionBlock.isIntroductionTextInfoArea && introductionBlock.isIntroductionVisualArea
                      "
                      [class.w100]="
                        introductionBlock.isIntroductionTextInfoArea && !introductionBlock.isIntroductionVisualArea
                      "
                    >
                      <h3 class="block__introduction__title jpfont" *ngIf="introductionBlock.title != ''">
                        {{ introductionBlock.title }}
                      </h3>
                      <p class="block__introduction__price" *ngIf="introductionBlock.price != ''">
                        {{ introductionBlock.price }}
                      </p>
                      <p
                        class="block__introduction__text"
                        *ngIf="introductionBlock.text != ''"
                        [innerHTML]="introductionBlock.textHTML"
                      ></p>
                    </div>
                    <div
                      class="block__introduction__section __sectionVisual"
                      *ngIf="introductionBlock.isIntroductionVisualArea"
                      [class.w50]="
                        introductionBlock.isIntroductionTextInfoArea && introductionBlock.isIntroductionVisualArea
                      "
                      [class.w100]="
                        !introductionBlock.isIntroductionTextInfoArea && introductionBlock.isIntroductionVisualArea
                      "
                    >
                      <div class="block__introduction__image secContent">
                        <div class="introductionBlock__eyecatch__inner">
                          <picture *ngIf="introductionBlock.image != ''">
                            <source
                              *ngIf="introductionBlock.webpImage"
                              type="image/webp"
                              srcset="{{ introductionBlock.webpImage }}"
                            />
                            <img
                              class="introductionBlock__eyecatch__inner__img"
                              src="{{ introductionBlock.image }}"
                              alt="{{ introductionBlock.imageAlt }}"
                              loading="lazy"
                              [class.width90]="introductionBlock.imageDisplayType == 2"
                              [class.width50]="introductionBlock.imageDisplayType == 3"
                            />
                          </picture>
                          <div
                            *ngIf="introductionBlock.imageIframe != ''"
                            class="introductionBlock__eyecatch__video"
                            [innerHTML]="introductionBlock.imageHTML"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- 店舗メニュー参照 -->
      <ng-container *ngIf="(b.blockType == 'referenceCategory' || b.blockType == 'referenceMenu') && b.isDisplayGroup">
        <div class="block">
          <!-- カテゴリ選択サイドバー（エンタープライズ・メニューブロック） -->
          <div
            class="category_selector_container"
            *ngIf="isCompany && b.position == 'menu__conceptBlock' && isUseCompanyMenu"
          >
            <div class="category_selector" [ngClass]="{ wide: isWideCategorySelector }">
              <div class="category_selector_header">
                <button class="resize_button" (click)="toggleCategorySelector()">
                  <div class="expand_mark" *ngIf="!isWideCategorySelector"></div>
                  <div class="shrink_mark" *ngIf="isWideCategorySelector"></div>
                </button>
                <div class="title jpfont">カテゴリ 一覧</div>
              </div>
              <div class="categorys">
                <!-- すべて -->
                <button
                  class="category all"
                  [ngClass]="{ selected: sideMenuSelectedCategoryId == -1 }"
                  (click)="selectCategory(-1)"
                >
                  <div class="category_name jpfont">すべて</div>
                </button>
                <!-- カテゴリ選択ボタン -->
                <ng-container *ngFor="let c of pds.sideBarCategoryList">
                  <button
                    class="category"
                    [ngClass]="{ selected: sideMenuSelectedCategoryId == c.id }"
                    (click)="selectCategory(c.id)"
                  >
                    <div class="category_name jpfont" *ngIf="c.title">{{ c.title }}</div>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="blockContent referenceMenu"
            [class.use_category_selector]="b.position == 'menu__conceptBlock'"
            *ngIf="isUseCompanyMenu"
          >
            <ul class="menu_list" [class.image_right]="b.imageLayout == 1">
              <ng-container *ngFor="let referenceMenuBlock of blockData">
                <ng-container *ngIf="b.groupId == referenceMenuBlock.groupId">
                  <ng-container *ngIf="referenceMenuBlock.blockType == 'referenceCategory'">
                    <div
                      #categoryBar
                      class="category_bar"
                      id="bc_{{ referenceMenuBlock.id }}"
                      [class.see_more_button_required]="
                        categoryBarContentInner.clientHeight > categoryBarContentInnerFake.clientHeight ||
                        seeMoreButtonRequiredStatus['bc_' + referenceMenuBlock.id]
                      "
                      [class.expand]="categoryBar.isExpand"
                      *ngIf="
                        sideMenuSelectedCategoryId < 0
                          ? true
                          : referenceMenuBlock.categoryBlockId == sideMenuSelectedCategoryId
                      "
                    >
                      <div class="category_bar__content" id="categoryBarContent_{{ referenceMenuBlock.id }}">
                        <div class="category_bar__content_inner_fake" #categoryBarContentInnerFake></div>
                        <div
                          class="category_bar__content_inner"
                          id="categoryBarContentInner_{{ referenceMenuBlock.id }}"
                          #categoryBarContentInner
                        >
                          <div class="category_bar__name jpfont" *ngIf="referenceMenuBlock.title">
                            {{ referenceMenuBlock.title }}
                          </div>
                          <div class="category_bar__details">
                            <div
                              class="category_bar__image"
                              *ngIf="
                                referenceMenuBlock.imageSettingType == 0 &&
                                referenceMenuBlock.image &&
                                referenceMenuBlock.isDisplayVisual
                              "
                            >
                              <img
                                src="{{ referenceMenuBlock.image }}"
                                alt="{{ referenceMenuBlock.imageAlt }}"
                                loading="lazy"
                              />
                            </div>
                            <div
                              class="category_bar__desc jpfont"
                              *ngIf="referenceMenuBlock.textHTML"
                              [innerHTML]="referenceMenuBlock.textHTML"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div class="category_bar__buttons">
                        <button
                          class="category_bar__expand_button"
                          *ngIf="!categoryBar.isExpand"
                          (click)="
                            seeMoreButtonRequiredStatus['bc_' + referenceMenuBlock.id] = true;
                            categoryBar.isExpand = !categoryBar.isExpand
                          "
                        >
                          もっとみる
                        </button>
                        <button
                          class="category_bar__shrink_button"
                          *ngIf="categoryBar.isExpand"
                          (click)="categoryBar.isExpand = !categoryBar.isExpand"
                        >
                          おりたたむ
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="referenceMenuBlock.blockType == 'referenceMenu'">
                    <div
                      #menuItem
                      class="menu_item"
                      id="bm_{{ referenceMenuBlock.id }}"
                      [class.see_more_button_required]="
                        menuItemContentInner.clientHeight > menuItemContentInnerFake.clientHeight ||
                        seeMoreButtonRequiredStatus['bm_' + referenceMenuBlock.id]
                      "
                      [class.expand]="menuItem.isExpand"
                      *ngIf="
                        sideMenuSelectedCategoryId < 0
                          ? true
                          : referenceMenuBlock.categoryBlockId == sideMenuSelectedCategoryId
                      "
                    >
                      <div class="menu_item__content">
                        <div class="menu_item__content_inner_fake" #menuItemContentInnerFake></div>
                        <div class="menu_item__content_inner" #menuItemContentInner>
                          <div class="menu_item__name jpfont" *ngIf="referenceMenuBlock.title">
                            {{ referenceMenuBlock.title }}
                          </div>
                          <div class="menu_item__details">
                            <div
                              class="menu_item__price_and_time"
                              *ngIf="referenceMenuBlock.time || referenceMenuBlock.price"
                            >
                              <div class="menu_item__time" [ngClass]="{ hide: !referenceMenuBlock.time }">
                                <span class="icon_time"></span>
                                {{ referenceMenuBlock.time }}分
                                <span *ngIf="referenceMenuBlock.rangeTime == '1'">〜</span>
                              </div>
                              <div class="menu_item__price" [ngClass]="{ hide: !referenceMenuBlock.price }">
                                {{ referenceMenuBlock.price }}
                                <span *ngIf="referenceMenuBlock.rangePrice == '1'">〜</span>
                              </div>
                            </div>
                            <div class="menu-item__image_and_description">
                              <div
                                class="menu_item__image"
                                *ngIf="
                                  referenceMenuBlock.imageSettingType == 1 &&
                                  referenceMenuBlock.image &&
                                  referenceMenuBlock.isDisplayVisual
                                "
                              >
                                <img
                                  src="{{ referenceMenuBlock.image }}"
                                  alt="{{ referenceMenuBlock.imageAlt }}"
                                  loading="lazy"
                                />
                              </div>
                              <div
                                class="menu_item__desc jpfont"
                                *ngIf="referenceMenuBlock.textHTML"
                                [innerHTML]="referenceMenuBlock.textHTML"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="menu_item__buttons">
                        <button
                          class="menu_item__expand_button"
                          *ngIf="!menuItem.isExpand"
                          (click)="
                            seeMoreButtonRequiredStatus['bm_' + referenceMenuBlock.id] = true;
                            menuItem.isExpand = !menuItem.isExpand
                          "
                        >
                          もっとみる
                        </button>
                        <button
                          class="menu_item__shrink_button"
                          *ngIf="menuItem.isExpand"
                          (click)="menuItem.isExpand = !menuItem.isExpand"
                        >
                          おりたたむ
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>
      <!-- テキスト -->
      <ng-container *ngIf="b.blockType == 'googleReview' && b.googleReviews.length > 0">
        <div class="block">
          <div class="blockContent googleReview">
            <div class="block__googleReview">
              <ng-container *ngFor="let review of b.googleReviews">
                <div class="googleReview__item">
                  <label class="googleReview__item__reviewerName jpfont">{{ review.reviewerDisplayName }}</label>
                  <div class="googleReview__item__starRatingAndPassedDatetime">
                    <div class="googleReview__item__starRating rating{{ review.starRating }}">
                      <label class="googleReview__item__starRating__star star1"></label>
                      <label class="googleReview__item__starRating__star star2"></label>
                      <label class="googleReview__item__starRating__star star3"></label>
                      <label class="googleReview__item__starRating__star star4"></label>
                      <label class="googleReview__item__starRating__star star5"></label>
                    </div>
                    <label class="googleReview__item__passedDatetime jpfont">{{ review.passedDatetime }}</label>
                  </div>
                  <label
                    #reviewComment
                    class="googleReview__item__comment jpfont checkEllipsis"
                    [class.ellipsis]="reviewComment.scrollHeight > reviewComment.clientHeight"
                    (load)="console.log('checkEllipsis')"
                    >{{ review.comment }}</label
                  >
                  <a
                    class="googleReview__item__moreBtn jpfont"
                    href="javascript:void(0);"
                    (click)="clickGoogleReviewMore(reviewComment)"
                    >もっとみる</a
                  >
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
