<section
  class="mobileAction"
  name="blur_aria_mobileAction"
  [class.blur_active]="blurActiveFlg"
  [class.browser]="showMobileActionBrowser"
  [class.app]="showMobileActionApp"
  [class.__modalStyle__]="isShopDetailNav"
>
  <div *ngIf="showMobileActionBrowser" class="mobileAction_container browser" id="mobileAction_container_browser">
    <a
      *ngIf="showAccessMobileBtn"
      id="accessMobileBottom"
      class="mobileAction__access btn_accessMobileBottom"
      [class.__modalStyle__]="isShopDetailNav"
      [class.showBtn]="isShowClassAccessMobileBtn"
      (click)="clickLink('shop')"
      >Access</a
    >
    <a
      id="shopListMobileBottom"
      *ngIf="showShopListMobileBtn"
      class="mobileAction__shopList btn_shopListMobileBottom"
      [class.__modalStyle__]="isShopDetailNav"
      (click)="openShopListMapModal()"
      >店舗一覧</a
    >
    <a
      id="bookMobileBottom"
      class="mobileAction__book btn_bookMobileBottom"
      [class.__modalStyle__]="isShopDetailNav"
      (click)="clickBooking()"
      >予約する</a
    >
  </div>

  <div *ngIf="showMobileActionApp" class="mobile-app-footer-container" id="mobileAction_container_app">
    <ul class="mobile-app-footer">
      <!-- 店舗一覧ボタン -->
      <li *ngIf="showShopListStandaloneBtn" class="">
        <div (click)="openShopListMapModal()" class="item">
          <div class="icon">
            <app-store-list-svg [isShopDetailNav]="this.isShopDetailNav"></app-store-list-svg>
          </div>
          <a class="text" [class.__modalStyle__]="isShopDetailNav">店舗一覧</a>
        </div>
      </li>
      <!-- 店舗一覧ボタン　ここまで -->
      <!-- 予約履歴ボタン -->
      <li class="">
        <div (click)="clickReservationHistory()" class="item">
          <div class="icon">
            <app-reservation-list-svg [isShopDetailNav]="this.isShopDetailNav"></app-reservation-list-svg>
          </div>
          <a class="text" [class.__modalStyle__]="isShopDetailNav">予約履歴</a>
        </div>
      </li>
      <!-- 予約履歴ボタン　ここまで -->
      <!-- 共有画像ボタン -->
      <li class="">
        <div (click)="clickShareImageList()" class="item">
          <div class="icon">
            <app-shared-image-svg [isShopDetailNav]="this.isShopDetailNav"></app-shared-image-svg>
          </div>
          <span class="text" [class.__modalStyle__]="isShopDetailNav">共有画像</span>
        </div>
      </li>
      <!-- 共有画像ボタン　ここまで -->
      <!-- メッセージボタン -->
      <li class="">
        <div (click)="clickMessage()" class="item">
          <ion-badge class="badge" color="danger" *ngIf="unreadCount > 0">{{ unreadCount }}</ion-badge>
          <div class="icon">
            <app-mail-svg [isShopDetailNav]="this.isShopDetailNav"></app-mail-svg>
          </div>
          <a class="text" [class.__modalStyle__]="isShopDetailNav">メッセージ</a>
        </div>
      </li>
      <!-- メッセージボタン　ここまで -->
      <!-- 予約ボタン -->
      <li class="">
        <div (click)="clickBooking()" class="item">
          <app-reservation-svg [isShopDetailNav]="this.isShopDetailNav"></app-reservation-svg>
        </div>
      </li>
      <!-- 予約ボタン　ここまで -->
    </ul>
  </div>
  <!-- /.mobileAction_container -->
</section>
<!-- /.mobileAction -->

<div
  *ngIf="showInstallMessage && !isClickedCloseForA2hs"
  class="mobileAction_balloonForiOS"
  id="mobileAction_balloonForiOS"
  [class.blur_active]="blurActiveFlg"
>
  <span class="mobileAction_balloonForiOS_text bold"
    ><img
      class="mobileAction_balloonForiOS_A2HS"
      src="./../../assets/images/icons/icon_A2HS.png"
      alt="icon_A2HS"
    />を押して「ホーム画面に追加」することでアプリとしてご利用いただけます。<br /><span
      class="mobileAction_balloonForiOS_text"
      >アプリでいつでも簡単に予約内容の確認・変更、メッセージの確認ができます。</span
    ><a class="mobileAction_balloonForiOS_okButton" (click)="closeInstallMessage()">閉じる</a></span
  >
</div>

<div
  *ngIf="isDeprecationMessage"
  class="mobileAction_balloonForiOS android"
  id="mobileAction_balloonForiOS"
  [class.blur_active]="blurActiveFlg"
>
  <label class="mobileAction_balloonForiOS_close" (click)="closeDeprecationMessage()">
    <svg style="width: 16px" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
      <path
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="M368 368L144 144M368 144L144 368"
      />
    </svg>
  </label>
  <span class="mobileAction_balloonForiOS_text">
    アプリとしてご利用いただくには、<br />ブラウザを<a
      class="mobileAction_balloonForiOS_text"
      href="https://play.google.com/store/apps/details?id=com.android.chrome"
      >Google Chrome</a
    >に変更してください<br /><a class="mobileAction_balloonForiOS_okButton copyUrl" (click)="copyUrl()"
      >このサイトのURLをコピーする</a
    ></span
  >
</div>

<div
  *ngIf="isSIBupdateMessage"
  class="mobileAction_balloonForiOS android"
  id="mobileAction_balloonForiOS"
  [class.blur_active]="blurActiveFlg"
>
  <label class="mobileAction_balloonForiOS_close" (click)="closeSIBupdateMessage()">
    <svg style="width: 16px" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
      <path
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        d="M368 368L144 144M368 144L144 368"
      />
    </svg>
  </label>
  <span class="mobileAction_balloonForiOS_text">
    アプリとしてご利用いただくには、<br />ブラウザを<a
      class="mobileAction_balloonForiOS_text"
      href="https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser"
      >アップデート</a
    >してください<br
  /></span>
</div>
<!-- Android用A2HSバナー -->
<ion-footer class="a2hsForA" *ngIf="isShowA2HS && !isClickedCloseForA2hs">
  <ion-toolbar class="a2hsForA_toolbar" (click)="addToHomeScreen()">
    <ion-buttons slot="start" class="a2hsForA_logo"
      ><img class="a2hsForA_logo_img" [src]="a2HSicon" alt="installLogo"
    /></ion-buttons>
    <span class="a2hsForA_txt" (click)="addToHomeScreen()"
      ><span class="a2hsForA_txt bold">アプリ {{ a2HSappName }} をインストール</span
      ><br />サイトをアプリとしてインストールしていただくと、いつでも簡単に予約内容の確認・変更、メッセージの確認ができます。</span
    >
    <ion-buttons slot="end" class="a2hsForA_close" (click)="closeInstallMessage()">
      <svg class="a2hsForA_close_btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="none"
          stroke="#000000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="32"
          d="M368 368L144 144M368 144L144 368"
        />
      </svg>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
