<!-- SNSエリア -->
<section
  class="snsIcons sec"
  *ngIf="layoutData.shareLinkFacebook || layoutData.shareLinkTwitter || layoutData.shareLinkInstagram"
>
  <!-- 例：モノクロ -->
  <!-- <section class="snsIcons sec mono">...</section> -->
  <div class="snsIcons_container">
    <ul class="snsIcons__list">
      <li class="snsIcons__item" *ngIf="layoutData.shareLinkInstagram">
        <a href="{{ layoutData.shareLinkInstagram }}" class="snsIcons__link" target="_brank">
          <img
            [defaultImage]="loadingImage"
            [lazyLoad]="instagram_icon"
            class="snsIcons__img snsIcons__img__instagram"
            alt="instagram_icon"
          />
        </a>
        <!-- <a href="{{layoutData.shareLinkInstagram}}" class="snsIcons__linkWhite" target="_brank"><img src="./assets/images/icons/icon-instagramWhite.svg" class="snsIcons__img snsIcons__img__instagramWhite"></a> -->
      </li>
      <li class="snsIcons__item" *ngIf="layoutData.shareLinkTwitter">
        <a href="{{ layoutData.shareLinkTwitter }}" class="snsIcons__link" target="_brank">
          <img
            [defaultImage]="loadingImage"
            [lazyLoad]="twitter_icon"
            class="snsIcons__img snsIcons__img__twitter"
            alt="twitter_icon"
          />
        </a>
        <!-- <a href="{{layoutData.shareLinkTwitter}}" class="snsIcons__linkWhite" target="_brank"><img src="./assets/images/icons/icon-twitterWhite.svg" class="snsIcons__img snsIcons__img__twitterWhite"></a> -->
      </li>
      <li class="snsIcons__item" *ngIf="layoutData.shareLinkFacebook">
        <a href="{{ layoutData.shareLinkFacebook }}" class="snsIcons__link" target="_brank">
          <img
            [defaultImage]="loadingImage"
            [lazyLoad]="fb_icon"
            class="snsIcons__img snsIcons__img__fb"
            alt="fb_icon"
          />
        </a>
        <!-- <a href="{{layoutData.shareLinkFacebook}}" class="snsIcons__linkWhite" target="_brank"><img src="./assets/images/icons/icon-fbWhite.svg" class="snsIcons__img snsIcons__img__fbWhite"></a> -->
      </li>
    </ul>
  </div>
  <!-- /.snsIcons_container -->
</section>
<!-- /.snsIcons -->

<section
  class="snsIcons_no sec"
  *ngIf="!layoutData.shareLinkFacebook && !layoutData.shareLinkTwitter && !layoutData.shareLinkInstagram"
></section>
<!-- /.snsIcons -->

<section class="homefooter">
  <div class="homefooter_container">
    <p
      class="homefooter_footerTitle"
      [innerHTML]="layoutData.footerTitle"
      [class.__modalStyle__]="isShopDetailNav"
      *ngIf="layoutData.footerTitle"
    ></p>

    <div
      class="homefooter_pcContainer"
      *ngIf="!isCompanyEnterprise"
      [class.noMap]="!(salonPageData.address && salonPageData.address.trim().length > 0)"
    >
      <div class="homefooter__body">
        <table class="homefooter__infoTable">
          <tr
            *ngIf="salonPageData.tel && salonPageData.tel.trim().length > 0"
            class="homefooter__infoTable__tr"
            [class.__modalStyle__]="isShopDetailNav"
          >
            <td class="homefooter__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
              <p>Tel</p>
            </td>
            <td class="homefooter__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
              <p>
                <a [href]="'tel:' + salonPageData.tel">{{ salonPageData.tel }}</a>
              </p>
            </td>
          </tr>
          <tr
            *ngIf="salonPageData.businessHours"
            class="homefooter__infoTable__tr"
            [class.__modalStyle__]="isShopDetailNav"
          >
            <td class="homefooter__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
              <p>営業時間</p>
            </td>
            <td class="homefooter__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
              <p [innerHTML]="salonPageData.businessHours"></p>
            </td>
          </tr>
          <tr *ngIf="salonPageData.holiday" class="homefooter__infoTable__tr" [class.__modalStyle__]="isShopDetailNav">
            <td class="homefooter__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
              <p>定休日</p>
            </td>
            <td class="homefooter__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
              <p [innerHTML]="salonPageData.holiday"></p>
            </td>
          </tr>
          <tr
            *ngIf="salonPageData.address && salonPageData.address.trim().length > 0"
            class="homefooter__infoTable__tr"
            [class.__modalStyle__]="isShopDetailNav"
          >
            <td class="homefooter__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
              <p>住所</p>
            </td>
            <td class="homefooter__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
              <p [innerHTML]="salonPageData.address"></p>
            </td>
          </tr>
          <tr *ngIf="salonPageData.access" class="homefooter__infoTable__tr" [class.__modalStyle__]="isShopDetailNav">
            <td class="homefooter__infoTable__td __title" [class.__modalStyle__]="isShopDetailNav">
              <p>アクセス</p>
            </td>
            <td class="homefooter__infoTable__td __text" [class.__modalStyle__]="isShopDetailNav">
              <p [innerHTML]="salonPageData.access"></p>
            </td>
          </tr>
        </table>
      </div>

      <div class="homefooter__map" *ngIf="salonPageData.address && salonPageData.address.trim().length > 0">
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.4886854567703!2d139.711713314567!3d35.66496733847246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b602a3f8dab%3A0xcf2d7367fdc7db54!2z5Y2X6Z2S5bGxIOe-juWuuemZoiDjg4njg4Pjg4jjgJBEb3QrTElN44CR576O5a655a6kLyBoYWlyIHNhbG9uIC8g44OY44Ki44K144Ot44Oz!5e0!3m2!1sja!2sjp!4v1551794716330" width="100%" frameborder="0" style="border:0" allowfullscreen></iframe> -->
        <iframe
          loading="lazy"
          id="homefooter_map"
          [src]="safeUrl"
          width="100%"
          frameborder="0"
          style="border: 0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</section>

<footer class="footer">
  <div class="footer_container">
    <nav class="footer_nav" *ngIf="showFooter">
      <ul class="footer_nav__list">
        <ng-container *ngFor="let m of layoutData.menus">
          <ng-container *ngIf="m.isDisplayCheckAppEnabled">
            <li class="footer_nav__item" [class.__modalStyle__]="isShopDetailNav">
              <a
                *ngIf="m.templateType == nil"
                class="footer_nav__link jpfont"
                (click)="clickLink(m.name)"
                [class.__modalStyle__]="isShopDetailNav"
                >{{ m.title }}</a
              >
              <a
                *ngIf="m.templateType == 'simple'"
                class="footer_nav__link jpfont"
                (click)="clickLink(m.name, { pageName: m.name })"
                [class.__modalStyle__]="isShopDetailNav"
                >{{ m.title }}</a
              >
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nav>
    <!-- /.footer_nav -->

    <div class="footer_copyright">
      <p
        *ngIf="layoutData.copyright && layoutData.copyright.trim().length > 0"
        class="footer_copyright__line jpfont"
        [class.__modalStyle__]="isShopDetailNav"
      >
        &copy;{{ layoutData.copyright }}
      </p>
    </div>
    <!-- /.footer_copyright -->
  </div>
  <!-- /.footer_container -->
</footer>
