<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="gallery_window_top"
  name="blur_aria"
  [class.stop_scroll]="openModalFlg"
  [class.blur_active]="(!isShopDetailNav && (blurActiveFlg || blurActiveFlgFromHeader)) || (isShopDetailNav && (blurActiveModalFlg || blurActiveModalFlgFromHeader))"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
  [class.__modalStyle__]="isShopDetailNav"
>
  <main class="main mainGallery content_top" name="blur_aria_gallery">
    <!-- ギャラリーページ見出し -->
    <section class="galleryHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <a
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></a>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <a
              class="breadcrumbs_item"
              (click)="clickLink( breadcrumbsData.url )"
              [class.__modalStyle__]="isShopDetailNav"
              >{{ breadcrumbsData.title }}</a
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <a href="javascript:void(0);" class="galleryHeading__backLink" (click)="navigationController.goBack()"><</a>
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="galleryHeading_container secContainer">
        <h2 class="galleryHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">
          {{ pageData.title }}
        </h2>
      </div>
      <div
        class="galleryBlock blockArea"
        *ngIf="blockData['gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__titleBlock'] && blockData['gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__titleBlock']"
          [position]="'gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <div class="galleryHeading_container secContainer">
        <p
          class="galleryHeading__desc"
          [innerHTML]="pageData.concept | htmlSanitize"
          [class.__modalStyle__]="isShopDetailNav"
        ></p>
        <!-- /.galleryHeading__desc -->
      </div>
      <div
        class="galleryBlock blockArea"
        *ngIf="blockData['gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__conceptBlock'] && blockData['gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__conceptBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__conceptBlock']"
          [position]="'gallery' + (pageData.brandId == null ? '' : '_' + pageData.brandId) + '__conceptBlock'"
          #appBlock
        ></app-block>
      </div>
      <!-- /.galleryHeading_container -->
    </section>
    <!-- /.galleryHeading -->

    <ng-container *ngIf="pageData.salonData.appEnabled == 5 && pageData.brandGalleryData.length > 0; else elseContent">
      <ng-container *ngFor="let brandData of pageData.brandGalleryData">
        <!-- ギャラリーコンテンツ -->
        <section class="galleryContent brandSection sec" *ngIf="brandData.pageData.isDisplay">
          <div class="galleryContent_container">
            <h2 class="galleryContent__heading" [class.__modalStyle__]="isShopDetailNav">{{ brandData.name }}</h2>

            <div class="galleryContent_container">
              <p
                class="galleryContent__desc"
                [innerHTML]="brandData.pageData.concept | htmlSanitize"
                [class.__modalStyle__]="isShopDetailNav"
              ></p>
              <!-- /.galleryHeading__desc -->
            </div>

            <div class="galleryContent__portfolio">
              <ul class="galleryContent__portfolio__list">
                <ng-container *ngFor="let c of brandData.gallery">
                  <li
                    *ngIf="!(c.staff.hasOwnProperty('id') && !c.staff.salonInfo.isPublic)"
                    class="galleryContent__portfolio__item aspectImg ratio_1x1 {{ c.categoryId }}"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <div (click)="galleryModal.openModal(c)" class="galleryContent__portfolio__link aspectImg__inner">
                      <picture>
                        <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpUrl" />
                        <img
                          [defaultImage]="loadingImage"
                          [lazyLoad]="c.url"
                          class="galleryContent__portfolio__link__inner"
                          [class.disp_cover]="pageData.isDisplayImageCover"
                        />
                      </picture>
                    </div>
                  </li>
                </ng-container>
                <li class="galleryContent__portfolio__dummy"></li>
              </ul>
            </div>
            <!-- /.galleryContent__portfolio -->

            <div class="galleryContent__btn secContent">
              <a (click)="clickLink( 'gallery', { brand_id: brandData.id })" class="galleryContent__btn__link btn_more"
                >もっとみる</a
              >
            </div>
            <!-- /.galleryContent__btn -->
          </div>
          <!-- /.galleryContent_container -->
        </section>
        <!-- /.galleryContent -->
      </ng-container>
    </ng-container>
    <ng-template #elseContent>
      <!-- ギャラリーコンテンツ -->
      <section class="galleryContent sec" *ngIf="isDisplayGallery">
        <div class="galleryContent_category" *ngIf="pageData.isDisplayCategory">
          <ul class="galleryContent_category__list">
            <!-- カテゴリー（ALL以外） -->
            <ng-container *ngFor="let c of pageData.category">
              <li class="galleryContent_category__item" *ngIf="c.description">
                <a
                  (click)="selectedCategory(c.id)"
                  class="galleryContent_category__link {{ c.selected }}"
                  id="gallery_category_{{ c.id }}"
                  *ngIf="c.description"
                  [class.__modalStyle__]="isShopDetailNav"
                  >{{ c.description }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>

        <div class="galleryContent_container">
          <p
            class="galleryContent__message"
            *ngIf="pageData.gallery.length == 0"
            [class.__modalStyle__]="isShopDetailNav"
          >
            選択したカテゴリの画像はありません
          </p>

          <div class="galleryContent__portfolio secContent">
            <ul class="galleryContent__portfolio__list">
              <ng-container *ngFor="let c of pageData.gallery">
                <li
                  *ngIf="!(c.staff.hasOwnProperty('id') && !c.staff.salonInfo.isPublic)"
                  class="galleryContent__portfolio__item aspectImg ratio_1x1 {{ c.categoryId }}"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  <div (click)="galleryModal.openModal(c)" class="galleryContent__portfolio__link aspectImg__inner">
                    <picture>
                      <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="c.webpUrl" />
                      <img
                        [defaultImage]="loadingImage"
                        [lazyLoad]="c.url"
                        class="galleryContent__portfolio__link__inner"
                        [class.disp_cover]="pageData.isDisplayImageCover"
                      />
                    </picture>
                  </div>
                </li>
              </ng-container>
              <li class="galleryContent__portfolio__dummy"></li>
            </ul>
          </div>
          <!-- /.galleryContent__portfolio -->
        </div>
        <!-- /.galleryContent_container -->
      </section>
      <!-- /.galleryContent -->
    </ng-template>
  </main>

  <!-- フッター -->
  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>
<!-- モーダル -->
<section class="galleryModal modal" id="gallery_modal" [class.open_modal]="openModalFlg">
  <app-gallerymodal
    [pageData]="pageData"
    (modalcontrol)="modalcontrol($event)"
    [dispModalStaffDetail]="true"
    [modalid]="'galleryModal__content'"
    [shopName]="this.shopName"
    [isShopDetailNav]="isShopDetailNav"
    #galleryModal
  ></app-gallerymodal>
</section>
<!-- /.galleryModal -->
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlg || blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
