<ion-content
  [scrollEvents]="true"
  *ngIf="!isShowPrivateMessage"
  class="content overflow_scroll"
  name="blur_aria"
  id="home_window_top"
  (ionScroll)="scrollEffect($event);mfbc.scrollEvent(content)"
  [class.stop_scroll]="openModalFlg"
  [class.blur_active]="(!isShopDetailNav && (blurActiveFlg || blurActiveFlgFromHeader)) || (isShopDetailNav && (blurActiveModalFlg || blurActiveModalFlgFromHeader))"
  [class.__modalStyle__]="isShopDetailNav"
  [attr.data-page_title]="pageData.pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  #homeWindowTop
>
  <div class="webLike" *ngIf="!pageData.appLike" [ngClass]="noHeaderBg">
    <div class="webLike_container">
      <!-- メインビジュアル（hero） -->
      <section class="hero content_top" id="hero">
        <div class="hero_container">
          <!-- <div class="hero__img aspect_5x6 pic" name="scroll_section__slide" id="heroImg"> -->
          <div name="scroll_section__slide" id="heroImg">
            <!-- 例：MVを1:1のアスペクト比 -->
            <!-- <div class="hero__img aspect_1x1">...</div> -->
            <!--<div class="hero__img__inner" [style.background-image]="'url(' + pageData.heroImageSp + ')'"></div> -->
            <div
              class="hero__img__inner"
              *ngIf="(pageData.heroImagePc || pageData.heroImagePcIframe) && pageData.isDisplayHeroImagePc"
            >
              <picture *ngIf="pageData.heroImagePcDisplayType != 4">
                <source
                  type="image/webp"
                  [attr.defaultImage]="loadingImage"
                  [attr.lazyLoad]="pageData.webpHeroImagePc"
                />
                <img
                  class="hero__img__inner__image"
                  [defaultImage]="loadingImage"
                  [lazyLoad]="pageData.heroImagePc"
                  alt="{{ pageData.heroImagePcAlt }}"
                  [class.width90]="pageData.heroImagePcDisplayType == 2"
                  [class.width50]="pageData.heroImagePcDisplayType == 3"
                />
              </picture>
              <div
                class="hero__img__video iframe_home"
                *ngIf="pageData.heroImagePcDisplayType == 4"
                [innerHTML]="heroImagePcHtml"
                #videoIframeContent
              ></div>
            </div>
            <!-- /.hero__img__inner -->
          </div>
          <!-- /.hero__img -->

          <!-- ロゴ無し メインビジュアル内位置 -->
          <a
            routerLink="/home"
            [queryParams]="pds.getRouteParams()"
            id="noLogoHero"
            class="noLogoHero enfont"
            *ngIf="pageData.noLogoHero"
            [ngClass]="noLogoHeroStyle"
            (click)="navigationController.setClickedRouterLink(true)"
            ><img src="{{ layoutData.logoImage }}" alt="logoImage"
          /></a>
        </div>
        <!-- /.hero_container -->
      </section>
      <!-- /.hero -->

      <!-- 「ロゴ無し メインビジュアル下部にロゴ表示 」-->
      <section class="noLogoOutside" *ngIf="pageData.noLogoOutside">
        <div class="noLogoOutside_container">
          <!-- ロゴ -->
          <a
            routerLink="/home"
            [queryParams]="pds.getRouteParams()"
            id="noLogoOutside_logo"
            class="noLogoOutside_logo enfont"
            (click)="navigationController.setClickedRouterLink(true)"
            ><img src="{{ layoutData.logoImage }}" alt="logoImage"
          /></a>
        </div>
        <!-- /.noLogoOutside_container -->
      </section>
      <!-- /.noLogoOutside -->

      <!-- Aboutエリア -->
      <section
        *ngIf="pageData.isDisplayAbout"
        [class.opacity]="effect_0 && isDisplayHeroImg"
        [class.scroll_section]="isDisplayHeroImg"
        class="topAbout sec"
        #scrollSection
        id="about"
        [class.__modalStyle__]="isShopDetailNav"
      >
        <div *ngIf="isDisplayAboutSec" class="topAbout_container secContainer rmMbLastContents">
          <h2
            *ngIf="pageData.aboutTitle"
            [class.opacity]="effect_0_3 && isDisplayHeroImg"
            name="scroll_section__slide"
            class="topAbout__heading secHeading enfont"
            id="aboutH1"
            [class.__modalStyle__]="isShopDetailNav"
          >
            {{ pageData.aboutTitle }}
          </h2>
          <div
            class="topAbout__block blockArea"
            [class.opacity]="effect_0_3 && isDisplayHeroImg"
            *ngIf="blockData['top__about__titleBlock'] && blockData['top__about__titleBlock'].length > 0"
          >
            <app-block
              [shopName]="this.shopName"
              [isShopDetailNav]="isShopDetailNav"
              [blockData]="blockData['top__about__titleBlock']"
              [position]="'top__about__titleBlock'"
              #appBlock
            ></app-block>
          </div>

          <div
            *ngIf="pageData.aboutConcept"
            [class.opacity]="effect_0_3 && isDisplayHeroImg"
            class="topAbout__desc secContent"
            name="scroll_section__slide"
          >
            <p [innerHTML]="pageData.aboutConcept | htmlSanitize"></p>
          </div>
          <!-- /.topAbout__desc -->
          <div
            class="topAbout__block blockArea"
            [class.opacity]="effect_0_3 && isDisplayHeroImg"
            *ngIf="blockData['top__about__descBlock'] && blockData['top__about__descBlock'].length > 0"
          >
            <app-block
              [shopName]="this.shopName"
              [isShopDetailNav]="isShopDetailNav"
              [blockData]="blockData['top__about__descBlock']"
              [position]="'top__about__descBlock'"
              #appBlock
            ></app-block>
          </div>

          <!--
        <div
          *ngIf="pageData.aboutImage"
          [class.opacity]="effect_0_3"
          class="topAbout__visual aspectImg ratio_2x1 secContent pic"
          name="scroll_section__slide"
        >-->
          <!-- <div class="topAbout__visual__inner aspectImg__inner" [style.background-image]="'url(' + pageData.aboutImage + ')'"></div> -->
          <!--
          <div
            class="topAbout__visual__inner aspectImg__inner"
            [defaultImage]="loadingImage"
            [lazyLoad]="pageData.aboutImage"
          ></div>
           -->
          <div
            *ngIf="(pageData.aboutImage || pageData.aboutImageIframe) && pageData.isDisplayAboutImage"
            [class.opacity]="effect_0_3 && isDisplayHeroImg"
            name="scroll_section__slide"
            class="topAbout__visual secContent pic"
          >
            <picture *ngIf="pageData.aboutImageDisplayType != 4">
              <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="pageData.webpAboutImage" />
              <img
                class="topAbout__visual__inner"
                [defaultImage]="loadingImage"
                [lazyLoad]="pageData.aboutImage"
                alt="{{ pageData.aboutImageAlt }}"
                [class.width90]="pageData.aboutImageDisplayType == 2"
                [class.width50]="pageData.aboutImageDisplayType == 3"
              />
            </picture>
            <div
              class="topAbout__visual__video iframe_home"
              *ngIf="pageData.aboutImageDisplayType == 4"
              [innerHTML]="aboutImageHtml"
              #videoIframeContent
            ></div>
          </div>
          <div
            class="topAbout__block blockArea"
            [class.opacity]="effect_0_3 && isDisplayHeroImg"
            *ngIf="blockData['top__about__visualBlock'] && blockData['top__about__visualBlock'].length > 0"
          >
            <app-block
              [shopName]="this.shopName"
              [isShopDetailNav]="isShopDetailNav"
              [blockData]="blockData['top__about__visualBlock']"
              [position]="'top__about__visualBlock'"
              #appBlock
            ></app-block>
          </div>
        </div>
        <!-- /.topAbout__visual -->
        <!-- </div>-->
        <!-- /.topAbout_container -->
      </section>
      <!-- /.topAbout -->
      <ng-container *ngFor="let m of pageData.menus; let isLast = last;" [ngSwitch]="m.name">
        <ng-container *ngIf="m.isDisplayCheckAppEnabled">
          <!-- メニューエリア -->
          <ng-container *ngSwitchCase="'menu'">
            <section
              *ngIf="pageData.isDisplayMenu"
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topMenu sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              #scrollSection
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div
                *ngIf="
                  pageData.menuTitle ||
                  pageData.menuConcept ||
                  ((pageData.menuImage || pageData.menuImageIframe) && pageData.isDisplayMenuImage)
                "
                class="topMenu_container secContainer"
              >
                <h2
                  *ngIf="pageData.menuTitle"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topMenu__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ pageData.menuTitle }}
                </h2>
                <div
                  class="topMenu__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__menu__titleBlock'] && blockData['top__menu__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__menu__titleBlock']"
                    [position]="'top__menu__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="pageData.menuConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topMenu__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="pageData.menuConcept | htmlSanitize"></p>
                </div>
                <!-- /.topMenu__desc -->
                <div
                  class="topMenu__block blockArea{{pageData.salonId}}"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__menu__descBlock'] && blockData['top__menu__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__menu__descBlock']"
                    [position]="'top__menu__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <!--
              <div
                *ngIf="pageData.menuImage"
                [class.opacity]="effect_0_3"
                class="topMenu__visual aspectImg ratio_2x1 secContent pic"
                name="scroll_section__slide"
              >
                <div
                  class="topMenu__visual__inner aspectImg__inner"
                  [defaultImage]="loadingImage"
                  [lazyLoad]="pageData.menuImage"
                ></div>
              </div>
              -->
                <div
                  *ngIf="(pageData.menuImage || pageData.menuImageIframe) && pageData.isDisplayMenuImage"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  name="scroll_section__slide"
                  class="topMenu__visual secContent pic"
                >
                  <picture *ngIf="pageData.menuImageDisplayType != 4">
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="pageData.webpMenuImage"
                    />
                    <img
                      class="topMenu__visual__inner"
                      [defaultImage]="loadingImage"
                      [lazyLoad]="pageData.menuImage"
                      alt="{{ pageData.menuImageAlt }}"
                      [class.width90]="pageData.menuImageDisplayType == 2"
                      [class.width50]="pageData.menuImageDisplayType == 3"
                    />
                  </picture>
                  <div
                    class="topMenu__visual__video iframe_home"
                    *ngIf="pageData.menuImageDisplayType == 4"
                    [innerHTML]="menuImageHtml"
                    #videoIframeContent
                  ></div>
                </div>
                <!-- </div> -->
                <!-- /.topMenu__visual -->
                <div
                  class="topMenu__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__menu__visualBlock'] && blockData['top__menu__visualBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__menu__visualBlock']"
                    [position]="'top__menu__visualBlock'"
                    #appBlock
                  ></app-block>
                </div>
                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topMenu__btn secContent"
                  name="scroll_section__slide"
                >
                  <a
                    class="topMenu__btn__link btn_more"
                    (click)="clickLink( 'menu' )"
                    [class.__modalStyle__]="isShopDetailNav"
                    >もっとみる</a
                  >
                </div>
                <!-- /.topMenu__btn -->
              </div>
              <!-- /.topMenu_container -->
            </section>
            <!-- /.topMenu -->
          </ng-container>
          <!-- スタッフエリア -->
          <ng-container *ngSwitchCase="'staff'">
            <section
              *ngIf="pageData.isDisplayStaff"
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topStaff sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              #scrollSection
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div
                *ngIf="
                  pageData.staffTitle ||
                  pageData.staffConcept ||
                  ((pageData.staffImage || pageData.staffImageIframe) && pageData.isDisplayStaffImage)
                "
                class="topStaff_container secContainer"
              >
                <h2
                  *ngIf="pageData.staffTitle"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topStaff__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ pageData.staffTitle }}
                </h2>
                <div
                  class="topStaff__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__staff__titleBlock'] && blockData['top__staff__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__staff__titleBlock']"
                    [position]="'top__staff__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="pageData.staffConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topStaff__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="pageData.staffConcept | htmlSanitize"></p>
                </div>
                <!-- /.topStaff__desc -->
                <div
                  class="topStaff__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__staff__descBlock'] && blockData['top__staff__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__staff__descBlock']"
                    [position]="'top__staff__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="(pageData.staffImage || pageData.staffImageIframe) && pageData.isDisplayStaffImage"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  name="scroll_section__slide"
                  class="topStaff__visual secContent pic"
                >
                  <picture *ngIf="pageData.staffImageDisplayType != 4">
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="pageData.webpStaffImage"
                    />
                    <img
                      class="topStaff__visual__inner"
                      [defaultImage]="loadingImage"
                      [lazyLoad]="pageData.staffImage"
                      alt="{{ pageData.staffImageAlt }}"
                      [class.width90]="pageData.staffImageDisplayType == 2"
                      [class.width50]="pageData.staffImageDisplayType == 3"
                    />
                  </picture>
                  <div
                    class="topStaff__visual__video iframe_home"
                    *ngIf="pageData.staffImageDisplayType == 4"
                    [innerHTML]="staffImageHtml"
                    #videoIframeContent
                  ></div>
                </div>
                <!-- /.topStaff__visual -->
                <div
                  class="topStaff__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__staff__visualBlock'] && blockData['top__staff__visualBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__staff__visualBlock']"
                    [position]="'top__staff__visualBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topStaff__btn secContent"
                  name="scroll_section__slide"
                >
                  <a
                    class="topStaff__btn__link btn_more"
                    (click)="clickLink( 'staff' )"
                    [class.__modalStyle__]="isShopDetailNav"
                    >もっとみる</a
                  >
                </div>
                <!-- /.topStaff__btn -->
              </div>
              <!-- /.topStaff_container -->
            </section>
            <!-- /.topStaff -->
          </ng-container>
          <!-- ショップエリア -->
          <ng-container *ngSwitchCase="'shop'">
            <section
              *ngIf="pageData.isDisplayShop"
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topShop sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              #scrollSection
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div
                *ngIf="
                  pageData.shopTitle ||
                  pageData.shopConcept ||
                  ((pageData.shopImage || pageData.shopImageIframe) && pageData.isDisplayShopImage)
                "
                class="topShop_container secContainer"
              >
                <h2
                  *ngIf="pageData.shopTitle"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topShop__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ pageData.shopTitle }}
                </h2>
                <div
                  class="topShop__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__shop__titleBlock'] && blockData['top__shop__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__shop__titleBlock']"
                    [position]="'top__shop__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="pageData.shopConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topShop__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="pageData.shopConcept | htmlSanitize"></p>
                </div>
                <!-- /.topShop__desc -->
                <div
                  class="topShop__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__shop__descBlock'] && blockData['top__shop__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__shop__descBlock']"
                    [position]="'top__shop__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="(pageData.shopImage || pageData.shopImageIframe) && pageData.isDisplayShopImage"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  name="scroll_section__slide"
                  class="topShop__visual secContent pic"
                >
                  <picture *ngIf="pageData.shopImageDisplayType != 4">
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="pageData.webpShopImage"
                    />
                    <img
                      class="topShop__visual__inner"
                      [defaultImage]="loadingImage"
                      [lazyLoad]="pageData.shopImage"
                      alt="{{ pageData.shopImageAlt }}"
                      [class.width90]="pageData.shopImageDisplayType == 2"
                      [class.width50]="pageData.shopImageDisplayType == 3"
                    />
                  </picture>
                  <div
                    class="topShop__visual__video iframe_home"
                    *ngIf="pageData.shopImageDisplayType == 4"
                    [innerHTML]="shopImageHtml"
                    #videoIframeContent
                  ></div>
                </div>
                <!-- /.topShop__visual -->
                <div
                  class="topShop__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__shop__visualBlock'] && blockData['top__shop__visualBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__shop__visualBlock']"
                    [position]="'top__shop__visualBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topShop__btn secContent"
                  name="scroll_section__slide"
                >
                  <a
                    class="topShop__btn__link btn_more"
                    (click)="clickLink( 'shop' )"
                    [class.__modalStyle__]="isShopDetailNav"
                    >もっとみる</a
                  >
                </div>
                <!-- /.topShop__btn -->
              </div>
              <!-- /.topShop-container -->
            </section>
            <!-- /.topShop -->
          </ng-container>
          <!-- ギャラリーエリア -->
          <ng-container *ngSwitchCase="'gallery'">
            <section
              *ngIf="pageData.isDisplayGallery"
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topGallery sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              #scrollSection
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div *ngIf="pageData.galleryTitle || pageData.galleryConcept" class="topGallery_container secContainer">
                <h2
                  *ngIf="pageData.galleryTitle"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topGallery__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ pageData.galleryTitle }}
                </h2>
                <div
                  class="topGallery__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__gallery__titleBlock'] && blockData['top__gallery__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__gallery__titleBlock']"
                    [position]="'top__gallery__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="pageData.galleryConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topGallery__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="pageData.galleryConcept | htmlSanitize"></p>
                </div>
                <!-- /.topGallery__desc -->
                <div
                  class="topGallery__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__gallery__descBlock'] && blockData['top__gallery__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__gallery__descBlock']"
                    [position]="'top__gallery__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <ng-container
                  *ngIf="pageData.salonData.appEnabled == 5 && pageData.brandGalleryData.length > 0; else elseContent"
                >
                  <ng-container *ngFor="let brandData of pageData.brandGalleryData">
                    <div
                      class="topGallery__brandSection"
                      [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                      *ngIf="brandData.gallery.length > 0 && brandData.pageData.isDisplay"
                    >
                      <h2
                        class="topGallery__brandSection__heading enfont"
                        name="scroll_section__slide"
                        [class.__modalStyle__]="isShopDetailNav"
                      >
                        {{ brandData.name }}
                      </h2>

                      <div
                        *ngIf="brandData.pageData.concept"
                        class="topGallery__brandSection__desc"
                        name="scroll_section__slide"
                      >
                        <p [innerHTML]="brandData.pageData.concept | htmlSanitize"></p>
                      </div>

                      <div class="topGallery__portfolio secContent pic" name="scroll_section__slide">
                        <ul class="topGallery__portfolio__list">
                          <ng-container *ngFor="let c of brandData.gallery">
                            <li
                              *ngIf="!(c.staff.hasOwnProperty('id') && !c.staff.salonInfo.isPublic)"
                              class="topGallery__portfolio__item aspectImg ratio_1x1"
                              [class.__modalStyle__]="isShopDetailNav"
                            >
                              <!-- <a class="topGallery__portfolio__link aspectImg__inner" [style.background-image]="'url(' + c.url + ')'"></a> -->
                              <div
                                (click)="galleryModal.openModal(c)"
                                class="topGallery__portfolio__link aspectImg__inner"
                              >
                                <picture>
                                  <source
                                    type="image/webp"
                                    [attr.defaultImage]="loadingImage"
                                    [attr.lazyLoad]="c.webpUrl"
                                  />
                                  <img
                                    [defaultImage]="loadingImage"
                                    [lazyLoad]="c.url"
                                    class="topGallery__portfolio__link__inner"
                                    [class.disp_cover]="pageData.galleryDisplayImageCover"
                                  />
                                </picture>
                              </div>
                            </li>
                          </ng-container>
                          <li class="topGallery__portfolio__dummy aspectImg"></li>
                        </ul>
                      </div>
                      <!-- /.topGallery__portfolio -->

                      <div class="topGallery__btn secContent" name="scroll_section__slide">
                        <a
                          class="topGallery__btn__link btn_more"
                          (click)="clickLink( 'gallery', { brand_id: brandData.id } )"
                          [class.__modalStyle__]="isShopDetailNav"
                          >もっとみる</a
                        >
                      </div>
                      <!-- /.topGallery__btn -->
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #elseContent>
                  <div
                    *ngIf="pageData.gallery.length > 0"
                    [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                    class="topGallery__portfolio secContent pic"
                    name="scroll_section__slide"
                  >
                    <ul class="topGallery__portfolio__list">
                      <ng-container *ngFor="let c of pageData.gallery">
                        <li
                          *ngIf="!(c.staff.hasOwnProperty('id') && !c.staff.salonInfo.isPublic)"
                          class="topGallery__portfolio__item aspectImg ratio_1x1"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          <!-- <a class="topGallery__portfolio__link aspectImg__inner" [style.background-image]="'url(' + c.url + ')'"></a> -->
                          <div (click)="galleryModal.openModal(c)" class="topGallery__portfolio__link aspectImg__inner">
                            <picture>
                              <source
                                type="image/webp"
                                [attr.defaultImage]="loadingImage"
                                [attr.lazyLoad]="c.webpUrl"
                              />
                              <img
                                [defaultImage]="loadingImage"
                                [lazyLoad]="c.url"
                                class="topGallery__portfolio__link__inner"
                                [class.disp_cover]="pageData.galleryDisplayImageCover"
                              />
                            </picture>
                          </div>
                        </li>
                      </ng-container>
                      <li class="topGallery__portfolio__dummy aspectImg"></li>
                    </ul>
                  </div>
                  <!-- /.topGallery__portfolio -->

                  <div
                    [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                    class="topGallery__btn secContent"
                    name="scroll_section__slide"
                  >
                    <a
                      class="topGallery__btn__link btn_more"
                      (click)="clickLink( 'gallery' )"
                      [class.__modalStyle__]="isShopDetailNav"
                      >もっとみる</a
                    >
                  </div>
                  <!-- /.topGallery__btn -->
                </ng-template>
              </div>
              <!-- /.topGallery-container -->
            </section>
            <!-- /.topGallery -->
          </ng-container>
          <!-- ニュースエリア -->
          <ng-container *ngSwitchCase="'news'">
            <section
              *ngIf="pageData.isDisplayNews"
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topNews sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              #scrollSection
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div *ngIf="pageData.newsTitle || pageData.newsConcept" class="topNews_container secContainer">
                <h2
                  *ngIf="pageData.newsTitle"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topNews__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ pageData.newsTitle }}
                </h2>
                <div
                  class="topNews__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__news__titleBlock'] && blockData['top__news__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__news__titleBlock']"
                    [position]="'top__news__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <h3
                  *ngIf="pageData.newsConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topNews__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="pageData.newsConcept | htmlSanitize"></p>
                </h3>
                <div
                  class="topNews__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__news__descBlock'] && blockData['top__news__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__news__descBlock']"
                    [position]="'top__news__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <p class="topNews__message" *ngIf="pageData.news.length == 0">新着情報はありません</p>

                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topNews__feed secContent"
                  name="scroll_section__slide"
                >
                  <ul class="topNews__feed__list">
                    <ng-container *ngFor="let newsItem of pageData.news; index as i;">
                      <li
                        class="topNews__feed__item"
                        [class.importantNews]="newsItem.important"
                        [class.__modalStyle__]="isShopDetailNav"
                        *ngIf="i < 3"
                      >
                        <a
                          class="topNews__feed__item__headLine"
                          [class.__modalStyle__]="isShopDetailNav"
                          (click)="clickLink( 'newsdetail' ,{ s: salonName, news_id: newsItem.id, p: isPreview, key: paramKey })"
                        >
                          <p class="topNews__feed__item__headLine__date" [class.__modalStyle__]="isShopDetailNav">
                            {{ newsItem.releaseDate }}
                          </p>
                          <p
                            class="topNews__feed__item__headLine__heading"
                            [innerHTML]="newsItem.title"
                            [class.important]="newsItem.important"
                            [class.__modalStyle__]="isShopDetailNav"
                          ></p>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
                <!-- /.topNews__feed -->
                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topNews__btn secContent"
                  name="scroll_section__slide"
                >
                  <a
                    class="topNews__btn__link btn_more"
                    (click)="clickLink( 'news' )"
                    [class.__modalStyle__]="isShopDetailNav"
                    >もっとみる</a
                  >
                </div>
                <!-- /.topNews__btn__btn -->
              </div>
              <!-- /.topNews_container -->
            </section>
            <!-- /.topNews -->
          </ng-container>
          <!-- キャンペーンエリア -->
          <ng-container *ngSwitchCase="'campaign'">
            <section
              *ngIf="pageData.isDisplayCampaign"
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topCampaign sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              #scrollSection
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div
                *ngIf="
                  pageData.campaignTitle ||
                  pageData.campaignConcept ||
                  ((pageData.campaignImage || pageData.campaignImageIframe) && pageData.isDisplayCampaignImage)
                "
                class="topCampaign_container secContainer"
              >
                <h2
                  *ngIf="pageData.campaignTitle"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topCampaign__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ pageData.campaignTitle }}
                </h2>
                <div
                  class="topCampaign__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__campaign__titleBlock'] && blockData['top__campaign__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__campaign__titleBlock']"
                    [position]="'top__campaign__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="pageData.campaignConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topCampaign__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="pageData.campaignConcept | htmlSanitize"></p>
                </div>
                <!-- /.topCampaign__desc -->
                <div
                  class="topCampaign__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__campaign__descBlock'] && blockData['top__campaign__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__campaign__descBlock']"
                    [position]="'top__campaign__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="(pageData.campaignImage || pageData.campaignImageIframe) && pageData.isDisplayCampaignImage"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  name="scroll_section__slide"
                  class="topCampaign__visual secContent pic"
                >
                  <picture *ngIf="pageData.campaignImageDisplayType != 4">
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="pageData.webpCampaignImage"
                    />
                    <img
                      class="topCampaign__visual__inner"
                      [defaultImage]="loadingImage"
                      [lazyLoad]="pageData.campaignImage"
                      alt="{{ pageData.campaignImageAlt }}"
                      [class.width90]="pageData.campaignImageDisplayType == 2"
                      [class.width50]="pageData.campaignImageDisplayType == 3"
                    />
                  </picture>
                  <div
                    class="topCampaign__visual__video iframe_home"
                    *ngIf="pageData.campaignImageDisplayType == 4"
                    [innerHTML]="campaignImageHtml"
                    #videoIframeContent
                  ></div>
                </div>
                <!-- /.topCampaign__visual -->

                <p class="topCampaign__message" *ngIf="pageData.coupon.length == 0">新着情報はありません</p>

                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topCampaign__feed secContent"
                  name="scroll_section__slide"
                  *ngIf="pageData.coupon.length != 0"
                >
                  <ul class="topCampaign__feed__list">
                    <li class="topCampaign__feed__item" *ngFor="let c of pageData.coupon">
                      <a
                        class="topCampaign__feed__item__headLine"
                        [class.__modalStyle__]="isShopDetailNav"
                        (click)="clickLink( 'campaign', {campaign_id: c.id} )"
                      >
                        <p class="topCampaign__feed__item__headLine__heading" [innerHTML]="c.title"></p>
                        <p class="topCampaign__feed__item__headLine__priceGroup" *ngIf="c.price || c.discount">
                          <span
                            class="topCampaign__feed__item__headLine__priceGroup__price"
                            *ngIf="c.price"
                            [class.__modalStyle__]="isShopDetailNav"
                            >￥<span class="topCampaign__feed__item__headLine__priceGroup__priceVal line"
                              >{{ c.price }}</span
                            ></span
                          >
                          <span
                            class="topCampaign__feed__item__headLine__priceGroup__arrow"
                            *ngIf="c.price && c.discount"
                            [class.__modalStyle__]="isShopDetailNav"
                            >&rarr;</span
                          >
                          <span
                            class="topCampaign__feed__item__headLine__priceGroup__discount"
                            *ngIf="c.discount"
                            [class.__modalStyle__]="isShopDetailNav"
                            >￥<span class="topCampaign__feed__item__headLine__priceGroup__discountVal"
                              >{{ c.discount }}</span
                            ></span
                          >
                        </p>
                        <p class="topCampaign__feed__item__headLine__date" [class.__modalStyle__]="isShopDetailNav">
                          {{ c.periodStart | date: 'yyyy/MM/dd' }} ～ {{ c.periodEnd | date: 'yyyy/MM/dd' }}
                        </p>
                      </a>
                    </li>
                  </ul>
                  <!-- /.topNews__feed__list -->
                </div>
                <!-- /.topNews__feed -->
                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topCampaign__btn secContent adjustContent"
                  name="scroll_section__slide"
                >
                  <a
                    class="topCampaign__btn__link btn_more"
                    (click)="clickLink( 'campaign' )"
                    [class.__modalStyle__]="isShopDetailNav"
                    >もっとみる</a
                  >
                </div>
                <!-- /.topCampaign__btn -->
              </div>
              <!-- /.topCampaign_container -->
            </section>
            <!-- /.topCampaign -->
          </ng-container>
          <ng-container *ngSwitchDefault>
            <!-- ページ追加（シンプル）エリア -->
            <section
              [class.opacity]="effect_0 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              class="topFreeSimple sec"
              [class.scroll_section]="((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
              [class.__modalStyle__]="isShopDetailNav"
              #scrollSection
              *ngIf="m.templateType == 'simple' && m.detail.isHomeDisplay"
            >
              <div
                *ngIf="m.detail.title || m.detail.homeConcept || m.detail.homeImage"
                class="topFreeSimple_container secContainer"
              >
                <h2
                  *ngIf="m.detail.title"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topFreeSimple__heading secHeading enfont"
                  name="scroll_section__slide"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ m.title }}
                </h2>
                <div
                  class="topFreeSimple__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__' + m.name + '__titleBlock'] && blockData['top__' + m.name + '__titleBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__' + m.name + '__titleBlock']"
                    [position]="'top__' + m.name + '__titleBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="m.detail.homeConcept"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topFreeSimple__desc secContent"
                  name="scroll_section__slide"
                >
                  <p [innerHTML]="m.detail.homeConcept | htmlSanitize"></p>
                </div>
                <!-- /.topFreeSimple__desc -->
                <div
                  class="topFreeSimple__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__' + m.name + '__descBlock'] && blockData['top__' + m.name + '__descBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__' + m.name + '__descBlock']"
                    [position]="'top__' + m.name + '__descBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  *ngIf="(m.detail.homeImage || m.detail.homeImageIframe) && m.detail.isDisplayHomeImage"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  name="scroll_section__slide"
                  class="topFreeSimple__visual secContent pic"
                >
                  <picture *ngIf="m.detail.homeImageDisplayType != 4">
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="m.detail.webpHomeImage"
                    />
                    <img
                      class="topFreeSimple__visual__inner"
                      [defaultImage]="loadingImage"
                      [lazyLoad]="m.detail.homeImage"
                      alt="{{ m.detail.homeImageAlt }}"
                      [class.width90]="m.detail.homeImageDisplayType == 2"
                      [class.width50]="m.detail.homeImageDisplayType == 3"
                    />
                  </picture>
                  <div
                    class="topFreeSimple__visual__video iframe_home"
                    *ngIf="m.detail.homeImageDisplayType == 4"
                    [innerHTML]="m.detail.homeImageHtml"
                    #videoIframeContent
                  ></div>
                </div>
                <!-- /.topFreeSimple__visual -->
                <div
                  class="topFreeSimple__block blockArea"
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  *ngIf="blockData['top__' + m.name + '__visualBlock'] && blockData['top__' + m.name + '__visualBlock'].length > 0"
                >
                  <app-block
                    [shopName]="this.shopName"
                    [isShopDetailNav]="isShopDetailNav"
                    [blockData]="blockData['top__' + m.name + '__visualBlock']"
                    [position]="'top__' + m.name + '__visualBlock'"
                    #appBlock
                  ></app-block>
                </div>

                <div
                  [class.opacity]="effect_0_3 && ((!isDisplayHeroImg && !isDisplayAboutSec && m.tmpDispNo > 0) || (isDisplayHeroImg || isDisplayAboutSec))"
                  class="topFreeSimple__btn secContent"
                  name="scroll_section__slide"
                >
                  <a
                    class="topFreeSimple__btn__link btn_more"
                    (click)="clickLink( m.name, { pageName: m.name } )"
                    [class.__modalStyle__]="isShopDetailNav"
                    >もっとみる</a
                  >
                </div>
                <!-- /.topFreeSimple__btn -->
              </div>
              <!-- /.topFreeSimple-container -->
            </section>
            <!-- /.topFreeSimple -->
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <app-homefooter
      [shopName]="this.shopName"
      [isShopDetailNav]="isShopDetailNav"
      [class.hiddenShowHomeFooter]="!isShowHomeFooter"
    ></app-homefooter>
  </div>
  <!-- /.webLike -->
</ion-content>

<!-- モーダル -->
<section class="galleryModal modal" id="home_modal" [class.open_modal]="openModalFlg">
  <app-gallerymodal
    [pageData]="pageData"
    (modalcontrol)="modalcontrol($event)"
    [dispModalStaffDetail]="true"
    [modalid]="'homeModal__content'"
    [isShopDetailNav]="isShopDetailNav"
    #galleryModal
  ></app-gallerymodal>
</section>
<!-- /.galleryModal -->

<!-- /.content overflow_scroll -->
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlg || blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
<app-emergencynews
  [pageData]="pageData"
  [salonData]="salonData"
  [isShowPrivateMessage]="isShowPrivateMessage"
  [isShopDetailNav]="isShopDetailNav"
  #appEmergencynews
></app-emergencynews>
