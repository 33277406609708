import { Component, OnDestroy, OnInit, ViewChild, ViewChildren, ElementRef, Input } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { GalleryPageData } from '../interfaces/galleryPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initGalleryPageData, initSalonData } from '../initData/initData';
import { NavigationController } from '../services/navigationController';
import { MetaDataController } from '../services/metaDataController';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoticeBlurActive } from '../services/noticeBlurActive';
import { NoticeBlurActiveForModal } from '../services/noticeBlurActiveForModal';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

import { RequestParamsService } from '../services/requestParams.service';
import { MobilefooterbuttonsComponent } from '../mobilefooterbuttons/mobilefooterbuttons.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.page.html',
  styleUrls: ['./gallery.page.scss']
})
export class GalleryPage implements OnInit, OnDestroy {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;
  @Input() requestParams: any;

  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('galleryModal', { static: false }) galleryModal;
  loadingImage = './assets/images/icons/loading-dot.png';
  isAllOrder: string = '1';

  pageData: GalleryPageData = initGalleryPageData;
  public blockData: any = [];
  public pageName: string;

  categoryId: number;
  categoryAllId: number;
  concept: SafeHtml;

  noPhoto: boolean = false;

  public salonData: SalonData = initSalonData;
  public isShowPrivateMessage: boolean = false;
  public privateMsgHtml: SafeHtml;
  // モーダルを開くでtrue、閉じるとfalse
  openModalFlg: boolean = false;
  // 他コンポーネントのぼかしクラスの制御
  blurActiveFlg: boolean = false;
  private subscription: Subscription;
  blurActiveFlgFromHeader: boolean = false;
  blurActiveModalFlgFromHeader: boolean = false;
  public paramKey: string = null;
  pageDataForPub: GalleryPageData; // プレビュー時使用
  isDisplayPrevPage: boolean = true;
  isDisplayPubPage: boolean = true;
  @ViewChild(MobilefooterbuttonsComponent, { static: false })
  private mfbc: MobilefooterbuttonsComponent;
  public brandId: number = null;
  public isDisplayGallery: boolean = true;

  public blockAreaListForAddBlock: any = {};
  @ViewChildren('appBlock') appBlockElement;
  @ViewChildren('videoIframeContent') videoIframeContentElement;
  public pageTitle: string;

  navigationSubscription;

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private sanitizer: DomSanitizer,
    private metaDataController: MetaDataController,
    private noticeBlurActive: NoticeBlurActive,
    private noticeBlurActiveForModal: NoticeBlurActiveForModal,
    public windowService: WindowService,
    private shopDetailModalService: ShopDetailModalService,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private requestParamsService: RequestParamsService,
    private elem: ElementRef,
    public router: Router
  ) {
    // エンタープライズのギャラリーから業種ギャラリーへの遷移時に必要な処理
    // NavigationEnd eventが起こった時に初期化メソッドを呼んで、リロードっぽく見せる
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // 他ページで実行しないように「/gallery」の制限をつける
      const params = this.requestParamsService.getRequestParams();
      if (this.pds.getIsCompany() && params.pageName === '/gallery' && e instanceof NavigationEnd) {
        this.load();
      }
    });
  }

  ngOnInit() {
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeBlurActiveFromHeader.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlgFromHeader = msg;
    });
    this.subscription = this.noticeBlurActiveFromHeaderForModal.sharedDataSource$.subscribe((msg) => {
      this.blurActiveModalFlgFromHeader = msg;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter() {
    this.isAllOrder = '1';
    // 戻るボタン・スワイプバックでこのページについた場合はスクロール位置を修正（データが残っているのでデータの読み直しはしない）
    // 動画の読み直しのためsetBlockを実行する
    // 進むボタン時はコンポーネント初期化されてデータがないので、elseへ
    if (!this.navigationController.getClickedRouterLink() && this.pageData.pageTitle != null) {
    } else {
      const promise = this.load();
      promise
        .then((result) => (this.content != undefined ? this.content.scrollToTop() : {}))
        .catch((e) => console.error(e))
        .finally(() => (this.content != undefined ? this.content.scrollToTop() : {}));
      if (this.openModalFlg) {
        this.galleryModal.closeModal('galleryModal__content');
      }
    }
    this.navigationController.setClickedRouterLink(false);
    this.navigationController.refreshUrl();
  }

  ionViewDidEnter() {
    if (this.openModalFlg) {
      this.addBlurActive();
    }
  }

  ionViewWillLeave() {
    this.removeBlurActive();
  }

  ionViewDidLeave() {
    // iframe要素を全て再セットする（停止処理）
    this.stopVideo();
  }

  private async load() {
    const params = this.requestParamsService.getRequestParams();
    this.pageName = params.pageName;
    this.paramKey = params.key;
    this.brandId = params.brandId;
    this.pageData = await this.pds.getGalleryPageData(0, 0, 0, this.isAllOrder, this.brandId, this.shopName);
    this.isDisplayGallery = this.pageData.gallery.length > 0; // 初回取得時にギャラリーが存在しない場合は非表示
    this.blockData = this.pageData.blockData;
    this.pds.remakeBlockData(this.blockData);
    this.salonData = await this.pds.getSalonData(this.shopName);
    if (!this.pageData.isDisplay && this.pds.getPreview()) {
      this.isDisplayPrevPage = false;
      this.pageDataForPub = await this.pds.getGalleryPageData(
        0,
        0,
        0,
        this.isAllOrder,
        this.brandId,
        this.shopName,
        false
      );
      if (!this.pageDataForPub.isDisplay) {
        this.isDisplayPubPage = false;
      }
    }
    // この条件の場合は非公開状態
    if ((!this.salonData.isPublic || this.salonData.publishedDate == null) && !this.pds.getPreview()) {
      this.isShowPrivateMessage = true;
    }

    console.log(this.pageData);
    if (!this.pageData.isDisplay && !this.pds.getPreview()) {
      if (this.isShopDetailNav) {
        this.shopDetailModalService.toHome(this.shopName);
      } else {
        this.navigationController.toHome();
      }
    }
    for (var i = 0; i < this.pageData.category.length; i++) {
      let category = this.pageData.category[i];
      if (category['categoryName'] == 'all') {
        this.categoryAllId = category['id'];
        this.categoryId = category['id'];
        this.pageData.category[i]['selected'] = 'selected';
      } else {
        this.pageData.category[i]['selected'] = '';
      }
    }

    // カテゴリの表示判定
    this.pageData.isDisplayCategory = this.checkDisplayCategory();

    this.pageTitle = this.pageData['pageTitle'];
    if (this.pageData['isDisplayEndTitle']) {
      this.pageTitle = this.pageTitle + '｜' + this.pageData['endTitle'];
    }
    this.metaDataController.updateTitleDescription(this.pageTitle, this.pageData['pageDescription']);
    this.metaDataController.deleteWebSiteStructuredData();
    this.metaDataController.updateOGTitleDescription(
      this.pageTitle,
      this.pageData['pageDescription'],
      this.navigationController.getCurrentUrl()
    );
  }

  addBlurActive() {
    // ヘッダーにぼかしクラスをつける
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(true);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(true);
    }
    this.blurActiveFlg = true;
  }

  removeBlurActive() {
    // ヘッダーにぼかしクラスを外す
    if (this.isShopDetailNav) {
      this.noticeBlurActiveForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActive.onNotifySharedDataChanged(false);
    }
    this.blurActiveFlg = false;
  }

  async selectedCategory(id) {
    this.categoryId = id;

    if (id == this.categoryAllId) {
      this.selectedAll();
      return;
    }

    console.log('selectedCategory:' + id);

    this.isAllOrder = '0';

    this.pageData = await this.pds.getGalleryPageData(id, 0, 0, this.isAllOrder, this.brandId, this.shopName);
    //this.galleryHasNext = this.pageData['hasNext'];
    //console.log('galleryHasNext:' + this.galleryHasNext);
    //this.galleryDataOffset = 9;

    for (var i = 0; i < this.pageData.category.length; i++) {
      let category = this.pageData.category[i];
      if (category['id'] == id) {
        this.pageData.category[i]['selected'] = 'selected';
      } else {
        this.pageData.category[i]['selected'] = '';
      }
    }
    // カテゴリの表示判定
    this.pageData.isDisplayCategory = this.checkDisplayCategory();

    // const listCount = document.getElementsByClassName('galleryContent__portfolio__item')
    // for(let i=0; i<listCount.length; i++) {
    //   listCount[i].classList.add('hiddenItem')
    // }
    // const selectedItem = document.getElementsByClassName(id)
    // for(let i=0; i<selectedItem.length; i++) {
    //   selectedItem[i].classList.remove('hiddenItem')
    // }
  }

  async selectedAll() {
    console.log('selectedAll:');

    this.isAllOrder = '1';

    this.pageData = await this.pds.getGalleryPageData(0, 0, 0, this.isAllOrder, this.brandId, this.shopName);

    //this.galleryHasNext = this.pageData['hasNext'];
    //console.log('galleryHasNext:' + this.galleryHasNext);
    //this.galleryDataOffset = 9;
    for (var i = 0; i < this.pageData.category.length; i++) {
      let category = this.pageData.category[i];
      if (category['categoryName'] == 'all') {
        this.pageData.category[i]['selected'] = 'selected';
      } else {
        this.pageData.category[i]['selected'] = '';
      }
    }
    // カテゴリの表示判定
    this.pageData.isDisplayCategory = this.checkDisplayCategory();

    // const listCount = document.getElementsByClassName('galleryContent__portfolio__item')
    // for(let i=0; i<listCount.length; i++) {
    //   listCount[i].classList.remove('hiddenItem')
    // }
  }

  checkDisplayCategory() {
    // カテゴリに空出ないものが存在するとき、カテゴリを表示する

    for (var i = 0; i < this.pageData.category.length; i++) {
      let category = this.pageData.category[i];
      if (category['categoryName'] != 'all') {
        if (category['description'] != '' && category['description'] != null) {
          return true;
        }
      }
    }
    return false;
  }

  modalcontrol(flg: boolean) {
    if (flg) {
      this.openModalFlg = true;
      this.addBlurActive();
    } else {
      this.openModalFlg = false;
      this.removeBlurActive();
    }
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      // 店舗詳細モーダルで開いた場合
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
  }

  // 動画を止める
  stopVideo() {
    // ページ内動画要素を取得する
    let videoIframeContentList = this.videoIframeContentElement.toArray();
    // 取得した結果、動画要素が存在した場合
    if (videoIframeContentList.length > 0) {
      // 各動画要素のinnerHTML(iframe部分)を再セットする
      for (let videoIframeContent of videoIframeContentList) {
        videoIframeContent.nativeElement.innerHTML = videoIframeContent.nativeElement.innerHTML;
      }
    }
    // 動画ブロックをリセットする
    let appBlockList = this.appBlockElement.toArray();
    for (let appBlock of appBlockList) {
      appBlock.resetVideoBlock();
    }
  }
}
