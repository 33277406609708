<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="news_window_top"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
  [class.__modalStyle__]="isShopDetailNav"
>
  <main class="main mainNews content_top">
    <!-- ニュースページ　見出し -->
    <section class="newsHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <a
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></a>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <a
              class="breadcrumbs_item"
              (click)="navigationController.setClickedRouterLink(true)"
              [class.__modalStyle__]="isShopDetailNav"
              >{{ breadcrumbsData.title }}</a
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <a href="javascript:void(0);" class="newsHeading__backLink" (click)="navigationController.goBack()"><</a>
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="newsHeading_container secContainer">
        <h2 class="newsHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">{{ pageData.title }}</h2>
      </div>
      <div
        class="newsBlock blockArea"
        *ngIf="blockData['news__titleBlock'] && blockData['news__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['news__titleBlock']"
          [position]="'news__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <div class="newsHeading_container secContainer">
        <p
          class="newsHeading__desc"
          [innerHTML]="pageData.concept | htmlSanitize"
          [class.__modalStyle__]="isShopDetailNav"
        ></p>
      </div>
      <div
        class="newsBlock blockArea"
        *ngIf="blockData['news__conceptBlock'] && blockData['news__conceptBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['news__conceptBlock']"
          [position]="'news__conceptBlock'"
          #appBlock
        ></app-block>
      </div>

      <!-- /.newsHeading_container -->
    </section>
    <!-- /.newsHeading -->

    <!-- ニュースエリア -->
    <section class="newsContent sec">
      <div class="newsContent_container secContainer">
        <p class="newsContent__message" *ngIf="pageData.news.length == 0" [class.__modalStyle__]="isShopDetailNav">
          新着情報はありません
        </p>

        <div class="newsContent__feed secContent">
          <ul class="newsContent__feed__list">
            <li
              class="newsContent__feed__item"
              *ngFor="let c of pageData.news"
              [class.importantNews]="c.important"
              [class.__modalStyle__]="isShopDetailNav"
            >
              <a
                class="newsContent__feed__item__headLine"
                (click)="clickLink( 'newsdetail', { s: salonName, news_id: c.id, p: isPreview, key: paramKey })"
                [class.__modalStyle__]="isShopDetailNav"
              >
                <p class="newsContent__feed__item__headLine__date" [class.__modalStyle__]="isShopDetailNav">
                  {{ c.releaseDate }}
                </p>
                <p
                  class="newsContent__feed__item__headLine__heading"
                  [class.important]="c.important"
                  [innerHTML]="c.title"
                  [class.__modalStyle__]="isShopDetailNav"
                ></p>
              </a>
            </li>
          </ul>
          <!-- /.newsContent__feed__list -->
        </div>
        <!-- /.newsContent__feed -->
      </div>
      <!-- /.newsContent_container -->
    </section>
    <!-- /.newsContent -->
  </main>
  <!-- /.mainStaff -->

  <!-- フッター -->
  <app-footer [shopName]="this.shopName" [isShopDetailNav]="isShopDetailNav" class="appFooter"></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
