<ion-content
  *ngIf="(pageData.isDisplay && !isShowPrivateMessage) || !isDisplayPrevPage"
  class="overflow_scroll"
  id="menu_window_top"
  name="blur_aria"
  [class.blur_active]="(!isShopDetailNav && blurActiveFlgFromHeader) || (isShopDetailNav && blurActiveModalFlgFromHeader)"
  [class.__modalStyle__]="isShopDetailNav"
  [scrollEvents]="true"
  [attr.data-page_title]="pageTitle"
  [attr.data-page_description]="pageData.pageDescription"
  (ionScroll)="mfbc.scrollEvent(content)"
>
  <main class="main mainMenu content_top">
    <!-- メニュー詳細見出し -->
    <section class="menuHeading sec">
      <div class="breadcrumbs" *ngIf="pageData.isDisplayBreadcrumbs; else elseIsBreadcurmbs">
        <ng-container *ngFor="let breadcrumbsData of pageData.breadcrumbsList">
          <a
            class="breadcrumbs_item home"
            *ngIf="breadcrumbsData.url == 'home'; else elseBreadcurmbsContent"
            (click)="clickLink( 'home' )"
            [class.__modalStyle__]="isShopDetailNav"
          ></a>
          <ng-template #elseBreadcurmbsContent>
            <label class="breadcrumbs_arrow" [class.__modalStyle__]="isShopDetailNav"> > </label>
            <a
              class="breadcrumbs_item"
              (click)="navigationController.setClickedRouterLink(true)"
              [class.__modalStyle__]="isShopDetailNav"
              >{{breadcrumbsData.title}}</a
            >
          </ng-template>
        </ng-container>
      </div>
      <ng-template #elseIsBreadcurmbs>
        <a
          href="javascript:void(0);"
          class="menuHeading__backLink"
          (click)="navigationController.goBack()"
          [class.__modalStyle__]="isShopDetailNav"
          ><</a
        >
      </ng-template>

      <div *ngIf="!isDisplayPrevPage" class="hiddenPageAlert">
        <div *ngIf="isDisplayPubPage" class="hiddenPageAlert__title__ForSave">このページは非表示予定です</div>
        <div *ngIf="!isDisplayPubPage" class="hiddenPageAlert__title__ForPublic">このページは現在非表示中です</div>
      </div>

      <div class="menuHeading_container secContainer">
        <h2 class="menuHeading__heading secHeading" [class.__modalStyle__]="isShopDetailNav">{{pageData.title}}</h2>
      </div>
      <div
        class="menuBlock blockArea"
        *ngIf="blockData['menu__titleBlock'] && blockData['menu__titleBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['menu__titleBlock']"
          [position]="'menu__titleBlock'"
          #appBlock
        ></app-block>
      </div>
      <div class="menuHeading_container secContainer">
        <p
          class="menuHeading__desc"
          [class.__modalStyle__]="isShopDetailNav"
          [innerHTML]="pageData.concept | htmlSanitize"
        ></p>
      </div>
      <div
        class="menuBlock blockArea"
        *ngIf="blockData['menu__conceptBlock'] && blockData['menu__conceptBlock'].length > 0"
      >
        <app-block
          [shopName]="this.shopName"
          [isShopDetailNav]="isShopDetailNav"
          [blockData]="blockData['menu__conceptBlock']"
          [position]="'menu__conceptBlock'"
          [isUseCompanyMenu]="isUseCompanyMenu"
          #appBlock
        ></app-block>
      </div>
      <!-- /.menuHeading_container -->
    </section>
    <!-- /.menuHeading -->
    <!-- メニューコンテンツ -->
    <!-- カテゴリ選択サイドバー -->
    <div
      class="category_selector_container"
      *ngIf="isViewCategoryEnabled && viewCategories.length > 0 && (salonData.appEnabled != 5 || salonData.appEnabled == 5 && salonData.isUseMenuFromCompany)"
    >
      <div
        class="category_selector"
        [ngClass]="{wide: isWideCategorySelector}"
        [class.__modalStyle__]="isShopDetailNav"
      >
        <div class="category_selector_header" [class.__modalStyle__]="isShopDetailNav">
          <button class="resize_button" (click)="toggleCategorySelector()" [class.__modalStyle__]="isShopDetailNav">
            <div class="expand_mark" *ngIf="!isWideCategorySelector" [class.__modalStyle__]="isShopDetailNav"></div>
            <div class="shrink_mark" *ngIf="isWideCategorySelector" [class.__modalStyle__]="isShopDetailNav"></div>
          </button>
          <div class="title jpfont" [class.__modalStyle__]="isShopDetailNav">カテゴリ 一覧</div>
        </div>
        <div class="categorys">
          <!-- すべて -->
          <button
            class="category all"
            [ngClass]="{selected: sideMenuSelectedCategoryId == -1}"
            (click)="selectCategory(-1)"
            [class.__modalStyle__]="isShopDetailNav"
          >
            <div class="category_name jpfont" [class.__modalStyle__]="isShopDetailNav">すべて</div>
          </button>
          <!-- カテゴリ選択ボタン -->
          <ng-container *ngFor="let c of viewCategories;">
            <button
              class="category"
              [ngClass]="{selected: sideMenuSelectedCategoryId == c.id}"
              (click)="selectCategory(c.id)"
              [class.__modalStyle__]="isShopDetailNav"
            >
              <div class="category_name jpfont" *ngIf="c.name" [class.__modalStyle__]="isShopDetailNav">
                {{ c.name }}
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <section
      class="menu sec"
      *ngIf="salonData.appEnabled != 5 || salonData.appEnabled == 5 && salonData.isUseMenuFromCompany"
      [class.__modalStyle__]="isShopDetailNav"
    >
      <!-- メニュー群 -->
      <div
        class="menu_container"
        [ngClass]="{'use_category_selector': isViewCategoryEnabled}"
        [class.__modalStyle__]="isShopDetailNav"
      >
        <!-- 税込・税抜文言 -->
        <div
          class="menu_tax_desc"
          *ngIf="isViewCategoryEnabled && (isDispTaxDesc || isDispTimeDesc) && viewCategories.length > 0"
          [class.__modalStyle__]="isShopDetailNav"
        >
          <span>※</span>
          <span class="menu_tax_desc_txt" *ngIf="isDispTaxDesc">価格は税込表示</span>
          <span *ngIf="isDispTaxDesc && isDispTimeDesc">、</span>
          <span class="menu_tax_desc_txt" *ngIf="isDispTimeDesc">時間は目安</span>
          <span>です。</span>
        </div>
        <!-- メニュー一覧 -->
        <ul class="menu_list" [class.image_right]="pageData.viewCategorySetting.imageLayout == 1">
          <!-- ---------- 表示カテゴリ　不使用店 --------------- -->
          <ng-container *ngIf="!isViewCategoryEnabled">
            <li class="menu_item no_category" *ngFor="let c of pageData.menu" [class.__modalStyle__]="isShopDetailNav">
              <div class="menu_item__details no_category">
                <h3
                  class="menu_item__title no_category jpfont"
                  [class.option_menu]="c.treatmentType != 0"
                  [class.__modalStyle__]="isShopDetailNav"
                  [innerHTML]="c.title"
                ></h3>
                <p
                  class="menu_item__desc no_category jpfont"
                  [innerHTML]="c.detail"
                  [class.__modalStyle__]="isShopDetailNav"
                ></p>
              </div>
            </li>
          </ng-container>
          <!-- ---------- 表示カテゴリ　使用店 ---------- -->
          <ng-container *ngIf="isViewCategoryEnabled">
            <ng-container *ngFor="let c of viewCategories;">
              <div class="category" *ngIf="sideMenuSelectedCategoryId < 0 ? true : c.id == sideMenuSelectedCategoryId">
                <!-- カテゴリバー -->
                <div
                  class="category_bar"
                  id="c_{{c.id}}"
                  [ngClass]="{'see_more_button_required': seeMoreButtonRequiredStatus['c_' + c.id], expand: seeMoreStatus['c_' + c.id]}"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  <div class="category_bar__content">
                    <div class="category_bar__content_inner">
                      <!-- カテゴリ名 -->
                      <div class="category_bar__name jpfont" *ngIf="c.name" [class.__modalStyle__]="isShopDetailNav">
                        {{ c.name }}
                      </div>
                      <!-- 説明文、画像 -->
                      <div class="category_bar__details">
                        <div
                          class="category_bar__image"
                          *ngIf="pageData.viewCategorySetting.imageSettingType == 0 && c.viewCategoryImageUrl"
                        >
                          <img src="{{ c.viewCategoryImageUrl }}" />
                        </div>
                        <div
                          class="category_bar__desc jpfont"
                          *ngIf="c.description"
                          [innerHTML]="c.description"
                          [class.__modalStyle__]="isShopDetailNav"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!-- もっとみるボタン -->
                  <div class="category_bar__buttons">
                    <button
                      class="category_bar__expand_button"
                      *ngIf="!seeMoreStatus['c_' + c.id]"
                      (click)="seeMoreStatus['c_' + c.id] = true"
                    >
                      もっとみる
                    </button>
                    <button
                      class="category_bar__shrink_button"
                      *ngIf="seeMoreStatus['c_' + c.id]"
                      (click)="seeMoreStatus['c_' + c.id] = false"
                    >
                      おりたたむ
                    </button>
                  </div>
                </div>
                <!-- /カテゴリバー -->
                <!-- メニュー -->
                <ng-container *ngFor="let m of c.viewCategoryTreatments;">
                  <li
                    class="menu_item"
                    id="m_{{m.id}}"
                    [ngClass]="{'see_more_button_required': seeMoreButtonRequiredStatus['m_' + m.id], expand: seeMoreStatus['m_' + m.id]}"
                    [class.__modalStyle__]="isShopDetailNav"
                  >
                    <div class="menu_item__content">
                      <div class="menu_item__content_inner">
                        <!-- メニュー名 -->
                        <h3
                          class="menu_item__name jpfont"
                          *ngIf="m.name"
                          [class.option_menu]="m.treatmentType != 0"
                          [class.__modalStyle__]="isShopDetailNav"
                        >
                          {{ m.name }}
                        </h3>
                        <div class="menu_item__details">
                          <!-- 所要時間、金額 -->
                          <div
                            class="menu_item__price_and_time"
                            *ngIf="(m.price != null || m.timeRequired != null) && m.viewCategoryId > 0"
                          >
                            <div
                              class="menu_item__time"
                              [ngClass]="{'hide': m.timeRequired === null}"
                              [class.__modalStyle__]="isShopDetailNav"
                            >
                              <span class="icon_time"></span>
                              {{ m.timeRequired }}分<span *ngIf="m.viewRangeRequiredTime == '1'">〜</span>
                            </div>
                            <div
                              class="menu_item__price"
                              [ngClass]="{'hide': m.price === null}"
                              [class.__modalStyle__]="isShopDetailNav"
                            >
                              ￥{{ m.price | number }}<span *ngIf="m.viewRangePrice == '1'">〜</span>
                            </div>
                          </div>
                          <!-- 画像、説明 -->
                          <div class="menu-item__image_and_description">
                            <div
                              class="menu_item__image"
                              *ngIf="pageData.viewCategorySetting.imageSettingType == 1 && m.treatmentImageUrl"
                            >
                              <img src="{{ m.treatmentImageUrl }}" />
                            </div>
                            <div
                              class="menu_item__desc jpfont"
                              *ngIf="m.description"
                              [innerHTML]="m.description"
                              [class.__modalStyle__]="isShopDetailNav"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- もっとみるボタン -->
                    <div class="menu_item__buttons">
                      <button
                        class="menu_item__expand_button"
                        *ngIf="!seeMoreStatus['m_' + m.id]"
                        (click)="seeMoreStatus['m_' + m.id] = true"
                      >
                        もっとみる
                      </button>
                      <button
                        class="menu_item__shrink_button"
                        *ngIf="seeMoreStatus['m_' + m.id]"
                        (click)="seeMoreStatus['m_' + m.id] = false"
                      >
                        おりたたむ
                      </button>
                    </div>
                  </li>
                </ng-container>
                <!-- /メニュー -->
              </div>
            </ng-container>
          </ng-container>
        </ul>
        <!-- /メニュー一覧 -->
      </div>
    </section>
  </main>
  <!-- /.mainMenu -->
  <!-- フッター -->
  <app-footer
    [shopName]="this.shopName"
    [isShopDetailNav]="isShopDetailNav"
    class="appFooter"
    [ngClass]="{'use_category_selector': isViewCategoryEnabled || isUseCompanyMenu}"
  ></app-footer>
</ion-content>
<app-private
  class="ion-page"
  *ngIf="isShowPrivateMessage"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
  [privateMsgHtml]="pageData.privateMsg | htmlSanitize"
></app-private>
<app-mobilefooterbuttons (scrollToTopEvent)="windowService.scrollToTop(content)"></app-mobilefooterbuttons>
<app-mobilefooter
  *ngIf="!isShowPrivateMessage && !isShopDetailNav"
  class="applike"
  [blurActiveFlg]="blurActiveFlgFromHeader"
  [shopName]="this.shopName"
  [isShopDetailNav]="isShopDetailNav"
></app-mobilefooter>
