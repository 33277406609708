<div
  *ngIf="showEmergencyNews && !isShowPrivateMessage"
  class="emergency_filter"
  id="emergency_filter"
  [class.__modalStyle__]="isShopDetailNav"
></div>
<div
  *ngIf="showEmergencyNews && !isShowPrivateMessage"
  class="emergency_news"
  id="emergency_news"
  [class.__modalStyle__]="isShopDetailNav"
>
  <div class="emergency_news__container" [class.__modalStyle__]="isShopDetailNav">
    <div class="emergency_news__header" [class.__modalStyle__]="isShopDetailNav">
      <svg
        class="emergency_news__close_btn"
        (click)="closeEmergencyNews()"
        xmlns="http://www.w3.org/2000/svg"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        [class.__modalStyle__]="isShopDetailNav"
      >
        <line
          x1="368"
          y1="368"
          x2="144"
          y2="144"
          style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px"
        />
        <line
          x1="368"
          y1="144"
          x2="144"
          y2="368"
          style="stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px"
        />
      </svg>
    </div>
    <div *ngFor="let newsItem of emergencyNewsData.news; let isLast = last; let isFirst = first;">
      <div
        class="emergency_news__contents sec rmMbLastContents"
        *ngIf="newsItem.emergency"
        [class.firstContents]="isFirst"
        [class.lastContents]="isLast"
        [class.__modalStyle__]="isShopDetailNav"
      >
        <h2 class="emergency_news__title jpfont" [class.__modalStyle__]="isShopDetailNav">{{newsItem.title}}</h2>
        <span class="emergency_news__date" [class.__modalStyle__]="isShopDetailNav">{{newsItem.releaseDate}}</span>
        <div
          class="emergencyNewsBlock blockArea"
          *ngIf="blockData['newsdetail' + newsItem.id + '__dateBlock'] && blockData['newsdetail' + newsItem.id + '__dateBlock'].length > 0"
        >
          <app-block
            [shopName]="this.shopName"
            [isShopDetailNav]="isShopDetailNav"
            [blockData]="blockData['newsdetail' + newsItem.id + '__dateBlock']"
            [position]="'newsdetail' + newsItem.id + '__dateBlock'"
            #appBlock
          ></app-block>
        </div>
        <div
          *ngIf="( newsItem.image || newsItem.imageIframe ) && newsItem.isDisplayImage"
          class="emergency_news__inner"
        >
          <picture *ngIf="newsItem.imageDisplayType != 4">
            <source type="image/webp" [attr.defaultImage]="loadingImage" [attr.lazyLoad]="newsItem.webpImage" />
            <img
              class="emergency_news__inner__img"
              [defaultImage]="loadingImage"
              [lazyLoad]="newsItem.image"
              alt="{{ newsItem.imageAlt }}"
              [class.width90]="newsItem.imageDisplayType == 2"
              [class.width50]="newsItem.imageDisplayType == 3"
            />
          </picture>
          <div
            class="emergency_news__video"
            *ngIf="newsItem.imageDisplayType == 4"
            [innerHTML]="newsItem.imageHtml"
            #videoIframeContent
          ></div>
        </div>
        <div
          class="emergencyNewsBlock blockArea"
          *ngIf="blockData['newsdetail' + newsItem.id + '__visualBlock'] && blockData['newsdetail' + newsItem.id + '__visualBlock'].length > 0"
        >
          <app-block
            [shopName]="this.shopName"
            [isShopDetailNav]="isShopDetailNav"
            [blockData]="blockData['newsdetail' + newsItem.id + '__visualBlock']"
            [position]="'newsdetail' + newsItem.id + '__visualBlock'"
            #appBlock
          ></app-block>
        </div>
        <p
          *ngIf="newsItem.body"
          class="emergency_news__body"
          [innerHTML]="newsItem.body | htmlSanitize"
          [class.__modalStyle__]="isShopDetailNav"
        ></p>
        <div
          class="emergencyNewsBlock blockArea"
          *ngIf="blockData['newsdetail' + newsItem.id + '__bodyBlock'] && blockData['newsdetail' + newsItem.id + '__bodyBlock'].length > 0"
        >
          <app-block
            [shopName]="this.shopName"
            [isShopDetailNav]="isShopDetailNav"
            [blockData]="blockData['newsdetail' + newsItem.id + '__bodyBlock']"
            [position]="'newsdetail' + newsItem.id + '__bodyBlock'"
            #appBlock
          ></app-block>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <a class="emergency_news__close_link" (click)="closeEmergencyNews()">閉じる</a>
    </div>
  </div>
</div>
